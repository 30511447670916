import { SET_NOTIFICHE, SET_UTENTE, SET_UTENTI, SET_UTENTI_PAGEABLE, SET_UTENTI_TAB } from '../actions/utenti';
import { UtentiAction } from '../actions/types';
import { INotifica, IUtente, IUtentiPageable } from '../../utils/model_types';

export interface ImpreseState {
    utentiPageable: IUtentiPageable | undefined;
    utenti: IUtente[];
    utente: IUtente | undefined;
    notifiche: INotifica[];
    tab: string;
}

const initialState: ImpreseState = {
    utentiPageable: undefined,
    utenti: [],
    utente: undefined,
    notifiche: [],
    tab: '',
};

const utentiReducer = (state = initialState, action: UtentiAction): ImpreseState => {
    switch (action.type) {
        case SET_UTENTI_PAGEABLE: {
            return {
                ...state,
                utentiPageable: action.payload.utentiPageable,
            };
        }
        case SET_UTENTI: {
            return {
                ...state,
                utenti: action.payload.utenti,
            };
        }
        case SET_UTENTE: {
            return {
                ...state,
                utente: action.payload.utente,
            };
        }
        case SET_NOTIFICHE: {
            return {
                ...state,
                notifiche: action.payload.notifiche,
            };
        }
        case SET_UTENTI_TAB: {
            return {
                ...state,
                tab: action.payload.tab,
            };
        }
        default:
            return state;
    }
};

export default utentiReducer;

import { SET_CATEGORIE_DOCUMENTAZIONE } from '../actions/documentazione';
import { ICategoriaDocumentazione } from '../../utils/model_types';
import { DocumentazioneAction } from '../actions/types';

export interface documentazioneState {
    categorieDocumentazione: ICategoriaDocumentazione[];
}

const initialState: documentazioneState = {
    categorieDocumentazione: [],
};

const documentazioneReducer = (state = initialState, action: DocumentazioneAction): documentazioneState => {
    switch (action.type) {
        case SET_CATEGORIE_DOCUMENTAZIONE: {
            const { categorieDocumentazione } = action.payload;
            return {
                ...state,
                categorieDocumentazione,
            };
        }
        default:
            return state;
    }
};

export default documentazioneReducer;

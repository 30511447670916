import { FAQsInfoAction } from '../actions/types';
import { SET_FAQSO, SET_FAQSN, SET_INFO } from '../actions/faqsInfo';
import { IFAQsInfo } from '../../utils/model_types';

export interface FAQsInfoState {
    faqO: IFAQsInfo[];
    faqN: IFAQsInfo[];
    info: IFAQsInfo[];
}

const initialState: FAQsInfoState = {
    faqO: [],
    faqN: [],
    info: [],
};

const faqsInfoReducer = (state = initialState, action: FAQsInfoAction): FAQsInfoState => {
    switch (action.type) {
        case SET_FAQSO: {
            const { faqO } = action.payload;
            return {
                ...state,
                faqO,
            };
        }
        case SET_FAQSN: {
            const { faqN } = action.payload;
            return {
                ...state,
                faqN,
            };
        }
        case SET_INFO: {
            const { info } = action.payload;
            return {
                ...state,
                info,
            };
        }
        default:
            return state;
    }
};

export default faqsInfoReducer;

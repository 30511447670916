import { SET_IMPRESE, SET_IMPRESE_PAGEABLE } from '../actions/imprese';
import { ImpreseAction } from '../actions/types';
import { IImpresaResponse, IImpresePageable } from '../../utils/model_types';

export interface ImpreseState {
    imprese: IImpresaResponse[];
    impresePageable: IImpresePageable;
}

const initialState: ImpreseState = {
    imprese: [],
    impresePageable: {imprese: [], pagination: { page: 0, size: 10 }},
};

const impreseReducer = (state = initialState, action: ImpreseAction): ImpreseState => {
    switch (action.type) {
        case SET_IMPRESE: {
            return {
                ...state,
                imprese: action.payload.imprese || [],
            };
        }
        case SET_IMPRESE_PAGEABLE: {
            return {
                ...state,
                impresePageable: action.payload.impresePageable,
            };
        }
        default:
            return state;
    }
};

export default impreseReducer;

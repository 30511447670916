import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../store/reducers';
import { IFormField, IUtente } from '../../../utils/model_types';
import FormElement from '../../FormElement/FormElement';
import CustomModal from '../CustomModal/CustomModal';

const SegnalazioneInviataModal = ({ open, form }: { open: boolean; form: { [key: string]: IFormField } }) => {
    const history = useHistory();
    const { utenti } = useSelector((state: RootState) => state.utenti);
    const [utenteSelezionato, setUtenteSelezionato] = useState<IUtente | null>(null);

    useEffect(() => {
        if (form && utenti) setUtenteSelezionato(utenti.find((u) => u.id === form.utente.value) as IUtente);
    }, [form, utenti]);

    const handleClickGoBack = () => {
        history.push('/calendario');
    };

    return (
        <>
            {utenteSelezionato && (
                <CustomModal title="Segnalazione inviata correttamente" open={open} size="md">
                    <div id="user-form">
                        <h4>Riepilogo</h4>
                        <div className="form-row grid-3">
                            <FormElement label={'Tipologia segnalazione:'}>
                                <input type="text" disabled defaultValue={form.tipoSegnalazione.value} />
                            </FormElement>
                            <FormElement label={'Utente selezionato:'}>
                                <input
                                    type="text"
                                    disabled
                                    defaultValue={utenteSelezionato.nome + ' ' + utenteSelezionato.cognome}
                                />
                            </FormElement>
                            <FormElement label={'Messaggio:'} className={'text-area-contatti'}>
                                <textarea disabled defaultValue={form.messaggio.value} />
                            </FormElement>
                        </div>
                        <div>
                            <button onClick={handleClickGoBack} className={'bg-primary'}>
                                Ritorna al Calendario
                            </button>
                        </div>
                    </div>
                </CustomModal>
            )}
        </>
    );
};

export default SegnalazioneInviataModal;

import React, { memo, useEffect, useState } from 'react';
import './Header.scss';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/actionCreators/auth';
import aniaLogo from '../../assets/img/logo_ania.png';
import { useHistory } from 'react-router-dom';
import { getRouteItems } from '../../routing/utils';
import { RootState } from '../../store/reducers';
import { IRoute } from '../../routing/types';
import { downloadExcelCalendario, searchConciliazione } from '../../store/actionCreators/conciliazione';
import { MOBILE_MAX_WIDTH } from '../../utils/constants';
import SelectDatesModal from '../Modals/SelectDatesModal/SelectDatesModal';
import { getClasses } from '../../utils/general';
import { IUtente } from '../../utils/model_types';

const { REACT_APP_HEADER_BG } = process.env;

const Header = memo(() => {
    const history = useHistory();
    const dispatch = useDispatch();

    const user = useSelector((state: RootState) => state.auth.user);
    const { ruoli } = useSelector((state: RootState) => state.enum);
    const { utente } = useSelector((state: RootState) => state.utenti);
    const { avviso } = useSelector((state: RootState) => state.avviso);

    const [pathName, setPathName] = useState<string>(history.location.pathname);
    const [userRoutes, setUserRoutes] = useState<IRoute[]>([]);
    const [ricerca, setRicerca] = useState<string>('');
    const [doRicerca, setDoRicerca] = useState<boolean>(false);
    const [toggleAreaPersonale, setToggleAreaPersonale] = useState<boolean>(false);
    const [openSelectDates, setOpenSelectDates] = useState<boolean>(false);

    useEffect(() => {
        setPathName(history.location.pathname);
        const handleToggle = () => {
            setToggleAreaPersonale(false);
        };
        document.addEventListener('click', handleToggle);

        return () => {
            document.removeEventListener('click', handleToggle);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const unlisten = history.listen(() => {
            setPathName(history.location.pathname);
        });
        return () => {
            unlisten();
        };
    }, [history, setPathName]);

    useEffect(() => {
        user && utente && setUserRoutes(getRouteItems(user, utente as IUtente));
    }, [user, utente]);

    useEffect(() => {
        if (doRicerca) {
            ricerca.trim() &&
                dispatch(searchConciliazione(dispatch, { ricerca: ricerca.trim(), setDoRicerca, history }));
            setDoRicerca(false);
        }
        // eslint-disable-next-line
    }, [doRicerca]);

    const signOut = () => {
        setPathName('');
        dispatch(logout(dispatch));
    };

    const handleClickMenuItem = (id: string) => {
        history.push(id);
        setPathName(id);
    };

    const handleDropdownAreaPersonale = (e: any) => {
        e.stopPropagation();
        setToggleAreaPersonale(!toggleAreaPersonale);
        // document.getElementsByTagName("body")[0]?.classList.toggle('overflow-hidden');
    };

    const handleRicerca = (value: string) => {
        setRicerca(value);
    };

    const handleDownloadExcelCalendario = ({ da, a }: { da: string; a: string }) => {
        dispatch(
            downloadExcelCalendario({ da, a }, 'conciliazioni-summary', () => setOpenSelectDates(!openSelectDates))
        );
    };

    return (
        <header id="header" style={{ background: REACT_APP_HEADER_BG || 'var(--secondary)' }}>
            <img
                src={aniaLogo}
                alt="Ania logo"
                onClick={() => {
                    history.push('/calendario');
                }}
            />
            <div className="row-menu">
                {userRoutes
                    ?.filter((route: IRoute) => route.showInHeader)
                    .map((route: IRoute) => {
                        return (
                            <div
                                key={route.path}
                                className={getClasses(
                                    'row-menu-item',
                                    pathName?.includes(route.path.toLocaleLowerCase()) ? ' active' : ''
                                )}
                                onClick={({ target: { id } }: any) => handleClickMenuItem(id)}
                                id={route.path}
                            >
                                {route.name}
                            </div>
                        );
                    })}
                {avviso && (
                    <div style={{ margin: 'auto', fontFamily: 'Titillium, serif' }}>
                        <i className="fas fa-bell" style={{ marginRight: '5px' }} />
                        <span> {avviso} </span>
                    </div>
                )}
            </div>
            {window.innerWidth > MOBILE_MAX_WIDTH && (
                <div className="search">
                    <input
                        type="text"
                        placeholder="ricerca"
                        value={ricerca}
                        onChange={({ target: { value } }) => handleRicerca(value)}
                        onKeyPress={(e: any) => {
                            if (e.key === 'Enter') {
                                setDoRicerca(true);
                            }
                        }}
                    />
                    <i className="fas fa-search" onClick={() => setDoRicerca(true)} />
                </div>
            )}
            <div className="area-personale" onClick={handleDropdownAreaPersonale}>
                {window.innerWidth < MOBILE_MAX_WIDTH ? (
                    toggleAreaPersonale ? (
                        <i className="fas fa-times" />
                    ) : (
                        <i className="fas fa-bars" />
                    )
                ) : (
                    <i className="fas fa-user-circle" />
                )}
                <div className={'collapse-menu' + (toggleAreaPersonale ? ' displayInitial' : '')}>
                    <div
                        className="collapse-menu-hello"
                        onClick={(e: any) => {
                            e.stopPropagation();
                        }}
                    >
                        Ciao, <span>{user?.username}</span>
                    </div>
                    {window.innerWidth <= MOBILE_MAX_WIDTH && (
                        <div className="search area-personale-item">
                            <input
                                type="text"
                                placeholder="ricerca"
                                value={ricerca}
                                onChange={({ target: { value } }) => handleRicerca(value)}
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                onFocus={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                onKeyPress={(e: any) => {
                                    if (e.key === 'Enter') {
                                        handleDropdownAreaPersonale(e);
                                        setDoRicerca(true);
                                    }
                                }}
                            />
                            <i
                                className="fas fa-search"
                                onClick={(e: any) => {
                                    handleDropdownAreaPersonale(e);
                                    setDoRicerca(true);
                                }}
                            />
                        </div>
                    )}
                    <div className="lateral-menu">
                        {userRoutes
                            ?.filter((route: IRoute) => route.showInHeader)
                            .map((route: IRoute) => {
                                return (
                                    <div
                                        className="area-personale-item"
                                        key={route.path}
                                        onClick={() => {
                                            history.push(route.path);
                                        }}
                                    >
                                        {route.name}
                                    </div>
                                );
                            })}
                    </div>
                    {user?.role === ruoli.ANIA_GEST && (
                        <>
                            <div
                                className="area-personale-item"
                                onClick={() => {
                                    setPathName('');
                                    history.push('/gestione-utenti');
                                }}
                            >
                                Gestione Utenti
                            </div>
                            <div
                                className="area-personale-item"
                                onClick={() => {
                                    setPathName('');
                                    history.push('/elenco-imprese');
                                }}
                            >
                                Gestione Imprese
                            </div>
                            <div
                                className="area-personale-item"
                                onClick={() => {
                                    setPathName('');
                                    history.push('/elenco-holding');
                                }}
                            >
                                Gestione Holding
                            </div>
                            <div
                                className="area-personale-item"
                                onClick={() => {
                                    setPathName('');
                                    history.push('/gestione-sedi');
                                }}
                            >
                                Gestione Sedi
                            </div>
                            <div className="area-personale-item" onClick={() => setOpenSelectDates(!openSelectDates)}>
                                Esporta statistiche
                            </div>
                        </>
                    )}
                    <div
                        className="area-personale-item"
                        onClick={() => {
                            setPathName('');
                            history.push('/profilo/' + user?.userID);
                        }}
                    >
                        Profilo
                    </div>
                    <div className="area-personale-item" onClick={signOut}>
                        Logout
                    </div>
                </div>
            </div>

            {/* SELEZIONA DATE PER EXCEL CALENDARIO */}
            {openSelectDates && (
                <SelectDatesModal
                    isOpen={openSelectDates}
                    handleOpen={() => setOpenSelectDates(!openSelectDates)}
                    handleDownload={handleDownloadExcelCalendario}
                />
            )}
        </header>
    );
});

export default Header;

import { HTMLAttributes, ReactNode } from 'react';
import './CustomModal.css';
import { getClasses } from '../../../utils/general';

interface CustomModalInterface extends HTMLAttributes<HTMLDivElement> {
    toggleModal?: any;
    children?: ReactNode;
    title: any;
    size?: 'sm' | 'md' | 'lg';
    open: boolean;
    titleImage?: any;
    titleClass?: string;
    id?: string;
    footer?: JSX.Element;
}

const { innerWidth } = window;
const breakpoint = 1024;

const CustomModal = ({
    toggleModal,
    children,
    title,
    size,
    open,
    titleImage,
    titleClass,
    id,
    footer,
    ...props
}: CustomModalInterface) => {
    return (
        <div
            id={id}
            className={getClasses(
                'custom-modal',
                innerWidth > breakpoint ? (size === 'lg' ? 'lg' : size === 'md' ? 'md' : 'sm') : 'lg',
                open ? 'show' : 'hide'
            )}
        >
            <div className={getClasses('modal-wrapper', props.className || '')} onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <div className={getClasses('title', titleClass as string)}>
                        {titleImage}
                        {title}
                    </div>
                    {toggleModal && <i className="fas fa-times" onClick={toggleModal} />}
                </div>
                <div className="modal-content">{children}</div>
                <div className="modal-footer form-row">{footer}</div>
            </div>
        </div>
    );
};

export default CustomModal;

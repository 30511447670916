import {
    IConciliazione,
    IConciliazioniPageable,
    ISearchedConciliazioniPageable,
    IUtente,
} from '../../utils/model_types';
import {
    SET_CCNL,
    SET_CONCILIAZIONE,
    SET_OPEN_CONTEXT_MENU,
    SET_CONCILIAZIONI,
    SET_CONCILIAZIONI_PAGEABLE,
    SET_DELEGHE,
    SET_LIVELLO_INQUADRAMENTO,
    SET_MOTIVI_CONCILIAZIONE,
    SET_SEARCHED_CONCILIAZIONI_PAGEABLE,
    SET_REFERENTI_IMPRESA,
} from '../actions/conciliazione';

export interface ConciliazioneState {
    conciliazione: IConciliazione | null;
    openContextMenu: IConciliazione | null;
    searchedConciliazioniPageable: ISearchedConciliazioniPageable;
    conciliazioniPageable: IConciliazioniPageable;
    conciliazioni: IConciliazione[];
    motiviConciliazione: any[];
    ccnl: any[];
    livelliInquadramento: any[];
    deleghe: any[];
    referentiImpresa: {
        id: string;
        referenteImpresa: IUtente;
    }[];
}

const initialState: ConciliazioneState = {
    conciliazione: null,
    openContextMenu: null,
    searchedConciliazioniPageable: {
        search: '',
        conciliazioni: [],
        pagination: {
            page: 0,
            size: 10,
        },
    },
    conciliazioniPageable: {
        conciliazioni: [],
        pagination: {
            page: 0,
            size: 10,
        },
    },
    conciliazioni: [],
    motiviConciliazione: [],
    ccnl: [],
    livelliInquadramento: [],
    deleghe: [],
    referentiImpresa: [],
};

const conciliazioneReducer = (state = initialState, action: any): ConciliazioneState => {
    switch (action.type) {
        case SET_CONCILIAZIONE: {
            const { conciliazione } = action.payload;
            return {
                ...state,
                conciliazione,
            };
        }
        case SET_OPEN_CONTEXT_MENU: {
            const { openContextMenu } = action.payload;
            return {
                ...state,
                openContextMenu,
            };
        }
        case SET_SEARCHED_CONCILIAZIONI_PAGEABLE: {
            const { searchedConciliazioniPageable } = action.payload;
            return {
                ...state,
                searchedConciliazioniPageable,
            };
        }
        case SET_CONCILIAZIONI_PAGEABLE: {
            const { conciliazioniPageable } = action.payload;
            return {
                ...state,
                conciliazioniPageable,
            };
        }
        case SET_CONCILIAZIONI: {
            const { conciliazioni } = action.payload;
            return {
                ...state,
                conciliazioni,
            };
        }
        case SET_MOTIVI_CONCILIAZIONE: {
            const { motiviConciliazione } = action.payload;
            return {
                ...state,
                motiviConciliazione,
            };
        }
        case SET_CCNL: {
            const { ccnl } = action.payload;
            return {
                ...state,
                ccnl,
            };
        }
        case SET_LIVELLO_INQUADRAMENTO: {
            const { livelliInquadramento } = action.payload;
            return {
                ...state,
                livelliInquadramento,
            };
        }
        case SET_DELEGHE: {
            const { deleghe } = action.payload;
            return {
                ...state,
                deleghe,
            };
        }
        case SET_REFERENTI_IMPRESA: {
            const { referentiImpresa } = action.payload;
            return {
                ...state,
                referentiImpresa,
            };
        }
        default:
            return state;
    }
};

export default conciliazioneReducer;

import {
    SET_RUOLI,
    SET_SEDI,
    SET_SESSI,
    SET_SINDACATI,
    SET_STATI_CONCILIAZIONE,
    SET_STATI_UTENTI,
    SET_TIPI_CONCILIAZIONE,
    SET_TIPI_GIORNATA,
} from '../actions/enum';
import { EnumAction, GeneralAction } from '../actions/types';
import { IEnumObj, ISede } from '../../utils/model_types';
import axiosInstance from '../../api/interceptor';
import { toggleSpinner, toggleToast } from './general';
import { Dispatch } from 'react';

const getObj = (array: string[]) => {
    const arrayToObj: IEnumObj = {};
    array.forEach((el) => (arrayToObj[el] = el));
    return arrayToObj;
};

export const setSedi = (sedi: ISede[]): EnumAction => {
    return {
        type: SET_SEDI,
        payload: { sedi },
    } as EnumAction;
};

export const setSindacati = (sindacati: string[]): EnumAction => {
    const sindacatiObj = getObj(sindacati);
    return {
        type: SET_SINDACATI,
        payload: { sindacati: sindacatiObj },
    } as EnumAction;
};

export const setRuoli = (ruoli: string[]): EnumAction => {
    const ruoliObj = getObj(ruoli);
    return {
        type: SET_RUOLI,
        payload: { ruoli: ruoliObj },
    } as EnumAction;
};

export const setSessi = (sessi: string[]): EnumAction => {
    const sessiObj = getObj(sessi);
    return {
        type: SET_SESSI,
        payload: { sessi: sessiObj },
    } as EnumAction;
};

export const setStatiUtente = (statiUtenti: string[]): EnumAction => {
    const statiUtentiObj = getObj(statiUtenti);
    return {
        type: SET_STATI_UTENTI,
        payload: { statiUtenti: statiUtentiObj },
    } as EnumAction;
};

export const setStatiConciliazione = (statiConciliazione: string[]): EnumAction => {
    const statiConciliazioneObj = getObj(statiConciliazione);
    return {
        type: SET_STATI_CONCILIAZIONE,
        payload: { statiConciliazione: statiConciliazioneObj },
    } as EnumAction;
};

export const setTipiConciliazione = (tipiConciliazione: string[]): EnumAction => {
    const tipiConciliazioneObj = getObj(tipiConciliazione);
    return {
        type: SET_TIPI_CONCILIAZIONE,
        payload: { tipiConciliazione: tipiConciliazioneObj },
    } as EnumAction;
};

export const setTipiGiornata = (tipiGiornata: string[]): EnumAction => {
    const tipiGiornataObj = getObj(tipiGiornata);
    return {
        type: SET_TIPI_GIORNATA,
        payload: { tipiGiornata: tipiGiornataObj },
    } as EnumAction;
};

export const getStaticContent = () => {
    return (dispatch: Dispatch<GeneralAction | EnumAction>) => {
        dispatch(toggleSpinner());
        Promise.all([
            // #0
            axiosInstance.get('/enumerators/ruolo'),
            // #1
            axiosInstance.get('/enumerators/sesso'),
            // #2
            axiosInstance.get('/enumerators/statoUtente'),
            // #3
            axiosInstance.get('/enumerators/statoConciliazione'),
            // #4
            axiosInstance.get('/enumerators/tipoConciliazione'),
            // #5
            axiosInstance.get('/enumerators/tipoGiornata'),
            // #6
            axiosInstance.get('/enumerators/sede'),
            // #7
            axiosInstance.get('/enumerators/sindacatoAppartenenza'),
        ])
            .then((response: any) => {
                dispatch(setRuoli(response[0].data));
                dispatch(setSessi(response[1].data));
                dispatch(setStatiUtente(response[2].data));
                dispatch(setStatiConciliazione(response[3].data));
                dispatch(setTipiConciliazione(response[4].data));
                dispatch(setTipiGiornata(response[5].data));
                dispatch(setSedi(response[6].data));
                dispatch(setSindacati(response[7].data));
                dispatch(toggleSpinner());
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', messaggio));
            });
    };
};

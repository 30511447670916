export const DATE_FORMAT = 'DD/MM/YYYY';
export const BE_DATE_FORMAT = 'YYYY-MM-DD';

export const ORA_CONCILIAZIONE_FORMAT = 'HH:mm:ss';
export const DESKTOP_MIN_WIDTH = 1025;
export const TABLET_MAX_WIDTH = 1024;
export const MOBILE_MAX_WIDTH = 576;
export const SLOT_INTERVAL = 5;
export const SLOT_PER_HOURS = 60 / SLOT_INTERVAL;
export const DAY_START = 9;
export const DAY_END = 18;
export const SLOTS_BEFORE_START = (DAY_START * SLOT_PER_HOURS) - 1;
export const SLOTS_BEFORE_END = DAY_END * SLOT_PER_HOURS;



export const MINUTES_HEIGHT = SLOT_INTERVAL * 3;

export const VINCOLI_PRENOTAZIONE_CONCILIAZIONE = 2;

export const TIPOLOGIA_INFO = {
    FAQO: 'FAQO',
    FAQN: 'FAQN',
    INFO: 'INFO',
};

export const STATI_DIPENDENTI = {
    PARTECIPATO: 'PARTECIPATO',
    NON_PARTECIPATO: 'NON_PARTECIPATO',
};

export const VALIDATION_MESSAGES: { [k: string]: string } = {
    required: 'Campo obbligatorio',
    email: 'Inserire un indirizzo email valido',
    pIva: 'Inserire una partita iva di 11 cifre',
    username: 'Sono consentiti lettere, numeri e i seguenti caratteri speciali: _ - @ .',
    password:
        'Minimo 8 caratteri, ' +
        'massimo 20 caratteri, ' +
        'almeno una lettera maiuscola, ' +
        'una lettera minuscola, ' +
        'un numero ' +
        'e uno tra i seguenti caratteri speciali: ! @ # $ % ^ & *',
    minLength: 'Minimo {minLength} caratteri',
    minValue: 'Minimo {minValue} dipendenti',
    wrongExtension: 'Estensione non supportata',
    dateEqualOrGreaterThan: 'Inserire una data uguale o successiva al {date}',
    equals: 'Le password devono coincidere',
    isAfterInizio: 'Inserire un orario successivo a {isAfterInizio}',
    fileTooLarge: 'Dimensione file troppo grande',
};

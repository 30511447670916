import React, { ReactNode } from 'react';
import './InternalPage.scss';

interface IProps {
    title: string;
    subtitle: string;
    children?: ReactNode;
    actionButton?: ReactNode;
    className?: string;
}

const InternalPage = ({ title, subtitle, children, actionButton, className }: IProps) => {
    return (
        <div id="internal-page" className={className || ''}>
            <div className="title">
                <h1 className="title-content">{title}</h1>
                <div className={'action-button'}>{actionButton}</div>
            </div>
            {subtitle && <div className="subtitle">{subtitle}</div>}
            {children}
        </div>
    );
};

export default InternalPage;

import {
    IAccettazioneConciliazioneRequest,
    IAssociazioneReferenteSindacale,
    IConciliazione,
    IConciliazioniPageable,
    IDragConciliazione,
    ISearchedConciliazioniPageable,
    ISede,
    IUtente,
} from '../../utils/model_types';
import { ConciliazioneAction, GeneralAction, GiornateAction } from '../actions/types';
import { Dispatch } from 'react';
import { toggleSpinner, toggleToast } from './general';
import axiosInstance from '../../api/interceptor';
import {
    SET_CCNL,
    SET_CONCILIAZIONE,
    SET_CONCILIAZIONI,
    SET_CONCILIAZIONI_PAGEABLE,
    SET_DELEGHE,
    SET_LIVELLO_INQUADRAMENTO,
    SET_MOTIVI_CONCILIAZIONE,
    SET_OPEN_CONTEXT_MENU,
    SET_REFERENTI_IMPRESA,
    SET_SEARCHED_CONCILIAZIONI_PAGEABLE,
} from '../actions/conciliazione';
import { getGiornate } from './giornate';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

export const setMotiviConciliazione = (motiviConciliazione: any[]): ConciliazioneAction => {
    return {
        type: SET_MOTIVI_CONCILIAZIONE,
        payload: { motiviConciliazione },
    } as ConciliazioneAction;
};
export const setCcnl = (ccnl: any[]): ConciliazioneAction => {
    return {
        type: SET_CCNL,
        payload: { ccnl },
    } as ConciliazioneAction;
};
export const setLivelloInquadramento = (livelliInquadramento: any[]): ConciliazioneAction => {
    return {
        type: SET_LIVELLO_INQUADRAMENTO,
        payload: { livelliInquadramento },
    } as ConciliazioneAction;
};

export const setDeleghe = (deleghe: any[]): ConciliazioneAction => {
    return {
        type: SET_DELEGHE,
        payload: { deleghe },
    } as ConciliazioneAction;
};

export const setReferentiImpresa = (
    referentiImpresa: {
        id: string;
        referenteImpresa: IUtente;
    }[]
): ConciliazioneAction => {
    return {
        type: SET_REFERENTI_IMPRESA,
        payload: { referentiImpresa },
    } as ConciliazioneAction;
};

export const setConciliazione = (conciliazione: IConciliazione | null): ConciliazioneAction => {
    return {
        type: SET_CONCILIAZIONE,
        payload: { conciliazione },
    } as ConciliazioneAction;
};

export const setOpenContextMenu = (openContextMenu: IConciliazione | null): ConciliazioneAction => {
    return {
        type: SET_OPEN_CONTEXT_MENU,
        payload: { openContextMenu },
    } as ConciliazioneAction;
};

export const setSearchedConciliazioniPageable = (
    searchedConciliazioniPageable: IConciliazioniPageable | null
): ConciliazioneAction => {
    return {
        type: SET_SEARCHED_CONCILIAZIONI_PAGEABLE,
        payload: { searchedConciliazioniPageable },
    } as ConciliazioneAction;
};

export const setConciliazioniPageable = (conciliazioniPageable: IConciliazioniPageable | null): ConciliazioneAction => {
    return {
        type: SET_CONCILIAZIONI_PAGEABLE,
        payload: { conciliazioniPageable },
    } as ConciliazioneAction;
};

export const setConciliazioni = (conciliazioni: IConciliazione[]): ConciliazioneAction => {
    return {
        type: SET_CONCILIAZIONI,
        payload: { conciliazioni },
    } as ConciliazioneAction;
};

export const getConciliazione = (
    dispatch: Dispatch<ConciliazioneAction | GeneralAction>,
    { idConciliazione }: { idConciliazione: string }
) => {
    return (dispatch: any) => {
        dispatch(toggleSpinner());

        axiosInstance
            .get('conciliazione/'.concat(idConciliazione))
            .then(({ data }) => {
                dispatch(toggleSpinner());
                dispatch(setConciliazione(data));
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', messaggio));
            });
    };
};

export const getConciliazioniNonConfermate = ({ idGiornata }: { idGiornata: string }) => {
    return (dispatch: Dispatch<ConciliazioneAction | GeneralAction>) => {
        dispatch(toggleSpinner());
        let url = 'conciliazione/nonConfermate';
        if (idGiornata) url = url.concat('/' + idGiornata);
        axiosInstance
            .get(url)
            .then(({ data }) => {
                const conciliazioni = data;
                dispatch(toggleSpinner());
                dispatch(setConciliazioni(conciliazioni));
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', messaggio));
            });
    };
};

export const addConciliazione = ({
    conciliazione,
    handleOpenConciliazione,
    handleOpenModaleMessaggio,
    url,
    sede,
    page,
    firstView,
    tipologiaGiornata,
}: {
    conciliazione: IConciliazione;
    handleOpenConciliazione: Function;
    handleOpenModaleMessaggio?: Function;
    url?: string;
    sede?: ISede | null;
    page?: number;
    firstView?: boolean;
    tipologiaGiornata?: string;
}) => {
    return (dispatch: Dispatch<ConciliazioneAction | GiornateAction | GeneralAction | any>) => {
        dispatch(toggleSpinner());
        axiosInstance
            .post(
                `conciliazione${url ? '/'.concat(url) : ''}`,
                url ? conciliazione.conciliazioniContenute : conciliazione
            )
            .then(() => {
                dispatch(toggleToast('success', 'Conciliazione richiesta correttamente'));
                dispatch(toggleSpinner());
                dispatch(setDeleghe([]));
                dispatch(setReferentiImpresa([]));
                handleOpenConciliazione();
                handleOpenModaleMessaggio && handleOpenModaleMessaggio();
                dispatch(setConciliazione(null));
                if (
                    tipologiaGiornata &&
                    (conciliazione.tipoConciliazione === 'PAUSA' || tipologiaGiornata === 'STRAORDINARIA')
                ) {
                    dispatch(getGiornate({ sede, firstView, page, tipologiaGiornata }));
                }
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', messaggio));
            });
    };
};

const actionsAfterUpdateConciliazione = (
    dispatch: Dispatch<ConciliazioneAction | GiornateAction | GeneralAction | any>,
    handleOpenConciliazione: Function | null
) => {
    dispatch(toggleToast('success', 'Conciliazione modificata correttamente'));
    dispatch(toggleSpinner());
    dispatch(setDeleghe([]));
    dispatch(setReferentiImpresa([]));

    if (handleOpenConciliazione) {
        handleOpenConciliazione();
        dispatch(setConciliazione(null));
    }
};

export const updateConciliazione = ({
    conciliazione,
    sede,
    handleOpenConciliazione,
    firstView,
    page,
    tipologiaGiornata,
}: {
    conciliazione: IConciliazione;
    sede: ISede | null;
    handleOpenConciliazione: Function | null;
    firstView?: boolean;
    page: number;
    tipologiaGiornata: string;
}) => {
    return (dispatch: Dispatch<ConciliazioneAction | GiornateAction | GeneralAction | any>) => {
        dispatch(toggleSpinner());
        axiosInstance
            .put('conciliazione/' + conciliazione.id, conciliazione)
            .then(() => {
                actionsAfterUpdateConciliazione(dispatch, handleOpenConciliazione);
                dispatch(getGiornate({ sede, firstView, page, tipologiaGiornata }));
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', messaggio));
            });
    };
};

export const updateConciliazioneNonConfermata = ({
    conciliazione,
    handleOpenConciliazione,
}: {
    conciliazione: IConciliazione;
    handleOpenConciliazione: Function | null;
}) => {
    return (dispatch: Dispatch<ConciliazioneAction | GiornateAction | GeneralAction | any>) => {
        dispatch(toggleSpinner());
        axiosInstance
            .put('conciliazione/' + conciliazione.id, conciliazione)
            .then(() => {
                dispatch(getConciliazioniNonConfermate({ idGiornata: conciliazione.idGiornata }));
                actionsAfterUpdateConciliazione(dispatch, handleOpenConciliazione);
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', messaggio));
            });
    };
};

export const dragConciliazione = (
    dispatch: Dispatch<GiornateAction | GeneralAction>,
    {
        dragConciliazioneObj,
        sede,
        firstView,
        page,
        tipologiaGiornata,
    }: {
        dragConciliazioneObj: IDragConciliazione[];
        sede: ISede | null;
        firstView: boolean;
        page: number;
        tipologiaGiornata: string;
    }
) => {
    return (dispatch: any) => {
        dispatch(toggleSpinner());
        axiosInstance
            .put('conciliazione/sposta', { spostamentiOra: dragConciliazioneObj })
            .then(({ data }) => {
                dispatch(getGiornate({ sede, firstView, page, tipologiaGiornata }));
                dispatch(toggleToast('success', 'Conciliazione spostata correttamente'));
                dispatch(toggleSpinner());
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', messaggio));
            });
    };
};

export const deleteConciliazione = (
    dispatch: Dispatch<GiornateAction | GeneralAction>,
    {
        conciliazione,
        sede,
        handleOpenConciliazione,
        firstView,
        page,
        tipologiaGiornata,
    }: {
        conciliazione: IConciliazione;
        sede: ISede | null;
        handleOpenConciliazione: Function;
        firstView: boolean;
        page: number;
        tipologiaGiornata: string;
    }
) => {
    const axiosDeleteConciliazione = (dispatch: any, conciliazione: IConciliazione) =>
        axiosInstance
            .put('conciliazione/annulla/' + conciliazione.id, conciliazione)
            .then(({ data }) => {
                dispatch(getConciliazioniNonConfermate({ idGiornata: conciliazione.idGiornata }));
                dispatch(getGiornate({ sede, firstView, page, tipologiaGiornata }));
                dispatch(toggleToast('success', 'Conciliazione annullata correttamente'));
                dispatch(toggleSpinner());
                dispatch(setConciliazione(null));
                handleOpenConciliazione();
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', messaggio));
            });

    return (dispatch: Dispatch<GeneralAction | ConciliazioneAction>) => {
        dispatch(toggleSpinner());

        /** COLLETTIVA */
        if (conciliazione.tipoConciliazione === 'COLLETTIVA') {
            Promise.all(
                conciliazione.conciliazioniContenute.map((c) => {
                    if (c?.dipendente?.id) {
                        return axiosInstance.delete('dipendente/' + c?.dipendente?.id);
                    }
                    return null;
                })
            ).then(() => axiosDeleteConciliazione(dispatch, conciliazione));
        } else {
        /** SINGOLA / DIGITALE */
            if (conciliazione?.dipendente?.id) {
                axiosInstance
                    .delete('dipendente/' + conciliazione?.dipendente?.id)
                    .then(() => {
                        axiosDeleteConciliazione(dispatch, conciliazione);
                    })
                    .catch((e) => {
                        const { messaggio } = e;
                        dispatch(toggleSpinner());
                        dispatch(toggleToast('error', messaggio));
                    });
            } else {
                axiosDeleteConciliazione(dispatch, conciliazione);
            }
        }
    };
};

export const closeConciliazione = (
    dispatch: Dispatch<GiornateAction | GeneralAction>,
    {
        conciliazioneId,
        statoConciliazione,
        sede,
        handleOpenConciliazione,
        firstView,
        page,
        tipologiaGiornata,
    }: {
        conciliazioneId: string;
        statoConciliazione: string;
        sede: ISede | null;
        handleOpenConciliazione: Function;
        firstView: boolean;
        page: number;
        tipologiaGiornata: string;
    }
) => {
    return (dispatch: any) => {
        dispatch(toggleSpinner());
        axiosInstance
            .put('conciliazione/chiusura/' + conciliazioneId + '?statoConciliazione=' + statoConciliazione)
            .then(({ data }) => {
                dispatch(getGiornate({ sede, firstView, page, tipologiaGiornata }));
                dispatch(toggleToast('success', 'Conciliazione chiusa correttamente'));
                dispatch(toggleSpinner());
                handleOpenConciliazione();
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', messaggio));
            });
    };
};

export const associaSindacalistaToConciliazione = (
    dispatch: Dispatch<GiornateAction | GeneralAction>,
    {
        associaSindacalista,
        sede,
        firstView,
        page,
        toggleModal,
        handleToggleDayMenu,
        tipologiaGiornata,
    }: {
        associaSindacalista: IAssociazioneReferenteSindacale[];
        sede: ISede | null;
        firstView: boolean;
        page: number;
        toggleModal: Function;
        handleToggleDayMenu?: Function;
        tipologiaGiornata: string;
    }
) => {
    return (dispatch: any) => {
        dispatch(toggleSpinner());
        axiosInstance
            .put('dipendente/associaSindacalista', associaSindacalista)
            .then(({ data }) => {
                dispatch(setConciliazione(null));
                dispatch(getGiornate({ sede, firstView, page, tipologiaGiornata }));
                dispatch(toggleToast('success', 'Conciliatore sindacale associato correttamente'));
                dispatch(toggleSpinner());
                toggleModal(null);
                if (handleToggleDayMenu) handleToggleDayMenu(null);
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', messaggio));
            });
    };
};

export const accettaConciliazione = (
    dispatch: Dispatch<GiornateAction | GeneralAction>,
    {
        conciliazioneAccettaRequest,
        toggleModal,
        firstView,
        page,
        sede,
        tipologiaGiornata,
    }: {
        conciliazioneAccettaRequest: IAccettazioneConciliazioneRequest[];
        toggleModal: Function;
        firstView: boolean;
        page?: number;
        sede: ISede | null;
        tipologiaGiornata: string;
    }
) => {
    return (dispatch: any) => {
        dispatch(toggleSpinner());
        axiosInstance
            .put('conciliazione/accettaConciliazione', conciliazioneAccettaRequest)
            .then(({ data }) => {
                dispatch(setConciliazione(null));
                dispatch(getGiornate({ sede, firstView, page, tipologiaGiornata }));
                dispatch(toggleToast('success', 'Conciliazione elaborata correttamente'));
                dispatch(toggleSpinner());
                toggleModal(null);
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', messaggio));
            });
    };
};

export const searchConciliazione = (
    dispatch: Dispatch<ConciliazioneAction | GeneralAction>,
    {
        ricerca,
        setDoRicerca,
        history,
        page,
        size,
    }: { ricerca: string; setDoRicerca?: Function; history: any; page?: number; size?: number }
) => {
    return (dispatch: any) => {
        dispatch(toggleSpinner());
        let url = 'conciliazione/ricerca';
        if (ricerca) url = url.concat('?campoRicerca=' + ricerca);
        if ((page === 0 || page) && size) {
            url = url.concat('&page=' + page + '&size=' + size);
        }
        axiosInstance
            .get(url)
            .then(({ data }) => {
                dispatch(toggleSpinner());
                history.push('/ricerca');
                const searchedConciliazioniPageable: ISearchedConciliazioniPageable = {
                    search: ricerca,
                    conciliazioni: data.content,
                    pagination: {
                        page: data.number,
                        size: data.size,
                        totalPages: data.totalPages,
                        totalElements: data.totalElements,
                    },
                };
                dispatch(setSearchedConciliazioniPageable(searchedConciliazioniPageable));
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                history.push('/ricerca');
                dispatch(setSearchedConciliazioniPageable(null));
                dispatch(toggleToast('error', messaggio));
            });

        if (setDoRicerca) setDoRicerca(false);
    };
};

export const downloadExcel = (
    dispatch: Dispatch<ConciliazioneAction | GeneralAction>,
    { ricerca }: { ricerca: string }
) => {
    return async (dispatch: any) => {
        dispatch(toggleSpinner());
        let url = 'conciliazione/export';
        if (ricerca) url = url.concat('?campoRicerca=' + ricerca);

        const headers = { 'Content-Type': 'blob' };
        const config: AxiosRequestConfig = { method: 'GET', url, responseType: 'arraybuffer', headers };

        axiosInstance
            .get(url, config)
            .then(({ data }) => {
                dispatch(toggleSpinner());
                const outputFilename = `${Date.now()}.xlsx`;
                const url = URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename);
                document.body.appendChild(link);
                link.click();
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', messaggio));
            });
    };
};

export const downloadExcelCalendario = (
    { da, a }: { da: string; a: string },
    url: string,
    handleOpenSelectDates: Function
) => {
    return async (dispatch: Dispatch<ConciliazioneAction | GeneralAction>) => {
        dispatch(toggleSpinner());
        let completeUrl = `/export/${url}?from=${da}&to=${a}`;

        const headers = { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' };
        const config: AxiosRequestConfig = { responseType: 'arraybuffer', headers };

        axiosInstance
            .get(completeUrl, config)
            .then((response: AxiosResponse) => {
                dispatch(toggleSpinner());
                const contentDisposition = response.request.getResponseHeader('Content-Disposition');
                const match = contentDisposition.match(/filename\s*=\s*"(.+)"/i);
                const filename = match[1];
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                handleOpenSelectDates();
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', messaggio));
            });
    };
};

export const downloadVerbaleCondiviso = (
    dispatch: Dispatch<ConciliazioneAction | GeneralAction>,
    { idConciliazione }: { idConciliazione: string }
) => {
    return (dispatch: any) => {
        dispatch(toggleSpinner());

        axiosInstance
            .get('conciliazione/verbale-condiviso/'.concat(idConciliazione))
            .then(({ data }) => {
                const a = document.createElement('a');
                a.href = data.fileData;
                a.download = data.nameFile || 'verbale-condiviso';
                a.click();
                dispatch(toggleSpinner());
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', messaggio));
            });
    };
};

export const downloadDelegaImpresa = (
    dispatch: Dispatch<ConciliazioneAction | GeneralAction>,
    { idConciliazione }: { idConciliazione: string }
) => {
    return (dispatch: any) => {
        dispatch(toggleSpinner());

        axiosInstance
            .get('conciliazione/delega-ref-imp/'.concat(idConciliazione))
            .then(({ data }) => {
                const a = document.createElement('a');
                a.href = data.fileData;
                a.download = data.nameFile || 'delega-impresa';
                a.click();
                dispatch(toggleSpinner());
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', messaggio));
            });
    };
};

export const downloadFileDipendente = (
    dispatch: Dispatch<ConciliazioneAction | GeneralAction>,
    { path, idDipendente }: { path: string; idDipendente: string }
) => {
    return (dispatch: any) => {
        dispatch(toggleSpinner());

        axiosInstance
            .get('dipendente/'.concat(path + '/').concat(idDipendente))
            .then(({ data }) => {
                const a = document.createElement('a');
                a.href = data.fileData;
                a.download = data.nameFile || path;
                a.click();
                dispatch(toggleSpinner());
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', messaggio));
            });
    };
};

export const getDipendenteStaticContent = (dispatch: Dispatch<ConciliazioneAction | GeneralAction>) => {
    return (dispatch: any) => {
        dispatch(toggleSpinner());

        Promise.all([
            axiosInstance.get('motivoConciliazione'),
            axiosInstance.get('enumerators/ccnl'),
            axiosInstance.get('enumerators/livelloInquadramento'),
        ])
            .then((response) => {
                dispatch(setMotiviConciliazione(response[0]?.data));
                dispatch(setCcnl(response[1]?.data));
                dispatch(setLivelloInquadramento(response[2]?.data));
                dispatch(toggleSpinner());
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', messaggio));
            });
    };
};

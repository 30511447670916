import { IConciliazione, IDipendente, IFormField, IUtente } from './model_types';
import moment from 'moment';
import { BE_DATE_FORMAT, ORA_CONCILIAZIONE_FORMAT } from './constants';
import { toggleToast } from '../store/actionCreators/general';
import { downloadFileUtente } from '../store/actionCreators/utenti';
import { downloadDelegaImpresa } from '../store/actionCreators/conciliazione';

export const getDipendentiCollettiva = (conciliazione: IConciliazione) => {
    return (
        conciliazione?.conciliazioniContenute &&
        conciliazione.conciliazioniContenute
            .sort((a: IConciliazione, b: IConciliazione) => {
                return moment(a.oraInizio, ORA_CONCILIAZIONE_FORMAT).diff(
                    moment(b.oraInizio, ORA_CONCILIAZIONE_FORMAT),
                    'minutes'
                );
            })
            .reduce((array: IDipendente[], currentConciliazione: IConciliazione) => {
                array.push(currentConciliazione.dipendente);
                return array;
            }, [])
    );
};

export function copyCSS(elem: { style: { cssText: string } }, origElem: any) {
    const computedStyle = getComputedStyle(origElem);

    if (computedStyle.cssText !== '') {
        elem.style.cssText = origElem.cssText;
    } else {
        elem.style.cssText = Object.values(computedStyle).reduce(
            (css, propertyName) => `${css}${propertyName}:${computedStyle.getPropertyValue(propertyName)};`
        );
    }
}

export function inlineStyles(
    elem: { querySelectorAll?: any; style: any },
    origElem: { querySelectorAll: (arg0: string) => any }
) {
    const children = elem.querySelectorAll('*');
    const origChildren = origElem.querySelectorAll('*');

    // copy the current style to the clone
    copyCSS(elem, origElem);

    // collect all nodes within the element, copy the current style to the clone
    Array.prototype.forEach.call(children, function (child, i) {
        copyCSS(child, origChildren[i]);
    });

    // strip margins from the outer element
    elem.style.margin =
        elem.style.marginLeft =
        elem.style.marginTop =
        elem.style.marginBottom =
        elem.style.marginRight =
            '';
}

export const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    }).then((data) => {
        return data;
    });

export const fieldIsValid = (formField: IFormField) => {
    // const cfRegex = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/;
    const usernameRegex = /^([a-zA-Z0-9_\-@.]*)$/;
    const pIvaRegex = /^[0-9]{11}$/;
    // OLD const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]*$/;
    const emailRegex =
        /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])*$/;
    /**
     * Password must contain at least one digit [0-9].
     * Password must contain at least one lowercase Latin character [a-z].
     * Password must contain at least one uppercase Latin character [A-Z].
     * Password must contain at least one special character like !@#$%^&*
     * Password must contain a length of at least 8 characters and a maximum of 20 characters.
     */
    const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;

    if (formField.validations.required) {
        formField.valid = formField.value?.replaceAll ? !!formField.value?.replaceAll(' ', '') : !!formField.value;
        formField.validationError = formField.valid ? null : 'required';
    }
    if (formField.valid && formField.validations.email) {
        formField.valid = emailRegex.test(formField.value);
        formField.validationError = formField.valid ? null : 'email';
    }
    if (formField.valid && formField.validations.password) {
        formField.valid = passwordRegex.test(formField.value);
        formField.validationError = formField.valid ? null : 'password';
    }
    if (formField.valid && formField.validations.pIva) {
        formField.valid = formField.value ? pIvaRegex.test(formField.value) : true;
        formField.validationError = formField.valid ? null : 'pIva';
    }
    if (formField.valid && formField.validations.username) {
        formField.valid = formField.value ? usernameRegex.test(formField.value) : true;
        formField.validationError = formField.valid ? null : 'username';
    }
    if (formField.valid && formField.validations.minLength) {
        formField.valid = formField.value.length >= formField.validations.minLength;
        formField.validationError = formField.valid ? null : 'minLength';
        formField.validationParam = formField.valid ? null : formField.validations.minLength;
    }
    if (formField.valid && formField.validations.minValue) {
        formField.valid = formField.value >= formField.validations.minValue;
        formField.validationError = formField.valid ? null : 'minValue';
        formField.validationParam = formField.valid ? null : formField.validations.minValue;
    }
    if (formField.valid && formField.validations.dateEqualOrGreaterThan && formField.value) {
        formField.valid =
            moment(formField.value, BE_DATE_FORMAT).diff(
                moment(formField.validations.dateEqualOrGreaterThan, BE_DATE_FORMAT),
                'days'
            ) >= 0;
        formField.validationError = formField.valid ? null : 'dateEqualOrGreaterThan';
        formField.validationParam = formField.valid ? null : formField.validations.dateEqualOrGreaterThan;
    }
    if (formField.valid && formField.validations.isAfterInizio && formField.value) {
        formField.valid =
            moment(formField.value, ORA_CONCILIAZIONE_FORMAT).diff(
                moment(formField.validations.isAfterInizio, ORA_CONCILIAZIONE_FORMAT),
                'minutes'
            ) > 0;
        formField.validationError = formField.valid ? null : 'isAfterInizio';
    }
    if (formField.valid && formField.validations.equals) {
        formField.valid = formField.value === formField.validations.equals;
        formField.validationError = formField.valid ? null : 'equals';
    }

    return formField;
};

export const getMessageWithParam = (message: string, param: string | number | null) => {
    if (param) return message.replace(/{.*}/, param.toString());
    return message;
};

export const capitalize = (value: string) => {
    return value.charAt(0).toUpperCase() + value.substring(1);
};

export const submitForm = (e: any, formFields: { [key: string]: IFormField }, setFormFields: Function) => {
    e.preventDefault();

    let formIsValid = true;
    Object.entries(formFields).forEach(([key, formField]: [key: string, formField: IFormField]) => {
        const formFieldValidated = fieldIsValid(formField);
        if (!formFieldValidated.valid) formIsValid = formFieldValidated.valid;

        setFormFields({
            ...formFields,
            [key]: formFieldValidated,
        });
    });

    if (!formIsValid) {
        return;
    }
    return formIsValid;
};

export const handleChangeFormFileInput = async ({
    e,
    field,
    setFile,
    formFields,
    setFormFields,
}: {
    e: any;
    field: IFormField;
    setFile: Function;
    formFields: { [key: string]: IFormField };
    setFormFields: Function;
}) => {
    const file = e?.target?.files[0];
    let newField = {};
    if (file) {
        const { valid, validationError } = fileValidation(file);
        if (!valid) {
            setFile(null);
            newField = { valid, validationError };
        } else {
            const base64 = await toBase64(file);
            setFile(base64);
            newField = {
                value: file?.name,
                valid,
                validationError,
            };
        }
    } else {
        newField = { valid: true, validationError: null };
    }
    setFormFields({
        ...formFields,
        [field.field]: { ...field, ...newField },
    });
};

export const handleChangeFileInput = async ({
    e,
    setFile,
    dispatch,
}: {
    e: any;
    setFile: Function;
    dispatch: Function;
}) => {
    const file = e?.target?.files[0];
    const { valid, validationError } = fileValidation(file);
    if (!valid) {
        dispatch(toggleToast('warning', validationError));
        setFile(null);
    } else {
        const base64 = await toBase64(file);
        setFile({ file: base64, nameFile: file?.name });
        return;
    }
};

const fileValidation = (file: File) => {
    const out = { valid: false, validationError: '' };
    if (!file || file.size <= 0) {
        out.validationError = `Il file è vuoto o corrotto`;
    } else if (fileTooLargest(file.size)) {
        out.validationError = `Dimensione del file troppo grande`;
    } else if (!extensionSupported(file.type)) {
        out.validationError = `Estensione ${file?.type} non supportata`;
    } else {
        out.valid = true;
    }
    return out;
};

const fileTooLargest = (size: number) => {
    const maxAllowedSize = 5 * 1024 * 1024;
    return size > maxAllowedSize;
};

const extensionSupported = (type: any) => {
    return 'image/png,image/jpg,image/jpeg,image/tiff,.heic,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf'.includes(
        type
    );
};

export const getRoleDescription = (role: string) => {
    let roleDescription = '';
    switch (role) {
        case 'REF_SIND': {
            roleDescription = 'CONCILIATORE SINDACALE';
            break;
        }
        case 'REF_IMP': {
            roleDescription = 'REFERENTE IMPRESA';
            break;
        }
        case 'ANIA_GEST': {
            roleDescription = 'ANIA GESTIONE';
            break;
        }
        case 'ANIA_CONC': {
            roleDescription = 'ANIA CONCILIATORE';
            break;
        }
        default: {
            roleDescription = role?.replaceAll('_', ' ');
            break;
        }
    }
    return roleDescription;
};

export const handleDownloadDelega = (
    e: any,
    id: string,
    conciliazione: IConciliazione | null,
    utente: IUtente | undefined,
    dispatch: any
) => {
    e.stopPropagation();
    e.preventDefault();
    if (conciliazione?.referenteImpresa?.hasMandato || utente?.hasMandato) {
        dispatch(
            downloadFileUtente({
                id: conciliazione?.referenteImpresa?.id || utente?.id || '',
                path: 'mandato',
                filename: 'delega.pdf',
            })
        );
    } else if (
        conciliazione?.flagDelegaReferenteImpresa ||
        conciliazione?.conciliazioniContenute?.find((c) => c.id === id)
    ) {
        dispatch(downloadDelegaImpresa(dispatch, { idConciliazione: id || '' }));
    }
};

export const handleConciliazioneIsStandard = (conciliazione: IConciliazione) => {
    return (
        (moment(conciliazione?.oraFine, ORA_CONCILIAZIONE_FORMAT).diff(
            moment(conciliazione?.oraInizio, ORA_CONCILIAZIONE_FORMAT),
            'minutes'
        ) === 20 &&
            conciliazione?.tipoConciliazione !== 'COLLETTIVA') ||
        (moment(conciliazione?.oraFine, ORA_CONCILIAZIONE_FORMAT).diff(
            moment(conciliazione?.oraInizio, ORA_CONCILIAZIONE_FORMAT),
            'minutes'
        ) ===
            20 + Number(conciliazione?.numeroPartecipanti) * 10 &&
            conciliazione?.tipoConciliazione === 'COLLETTIVA')
    );
};

export const handleRoundTime = (value: any) => {
    const hours = value.substr(0, 2);
    let minutes = value.substr(3, 2);

    const resto = Number(minutes) % 10;

    if (resto > 0 && resto < 5) minutes = Number(minutes) - (Number(minutes) % 10);
    else if (resto >= 5 && resto < 10) minutes = Number(minutes) + (10 - resto);

    if (minutes < 10) minutes = String(0).concat(minutes);

    return moment(hours.concat(':').concat(minutes), 'HH:mm').format(ORA_CONCILIAZIONE_FORMAT);
};

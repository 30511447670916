import { IGiornataResponse, IGiornataSimple, IGiornatePageable } from '../../utils/model_types';
import { SET_GIORNATE_LIST, SET_GIORNATE, SET_GIORNATE_PAGEABLE, SET_TIPOLOGIA_GIORNATA } from '../actions/giornate';

export interface GiornateState {
    giornate: IGiornataResponse[] | [] | undefined;
    giornatePageable: IGiornatePageable;
    giornateList: IGiornataSimple[];
    tipologiaGiornata: string;
}

const initialState: GiornateState = {
    giornate: [],
    giornatePageable: {
        giornate: [],
        pagination: {
            page: 0,
            size: 5,
            totalPages: 1,
        },
    },
    giornateList: [],
    tipologiaGiornata: 'ORDINARIA',
};

const giornateReducer = (state = initialState, action: any): GiornateState => {
    switch (action.type) {
        case SET_GIORNATE: {
            const { giornate } = action.payload;
            return {
                ...state,
                giornate,
            };
        }
        case SET_GIORNATE_PAGEABLE: {
            const { giornatePageable } = action.payload;
            return {
                ...state,
                giornatePageable,
            };
        }
        case SET_GIORNATE_LIST: {
            const { giornateList } = action.payload;
            return {
                ...state,
                giornateList,
            };
        }
        case SET_TIPOLOGIA_GIORNATA: {
            const { tipologiaGiornata } = action.payload;
            return {
                ...state,
                tipologiaGiornata,
            };
        }
        default:
            return state;
    }
};

export default giornateReducer;

import { TOGGLE_LOADING, TOGGLE_MENU, SELECTED_PAGE, RESET_STORE } from '../actions/general';

import { toast } from 'react-toastify';
import { GeneralAction } from '../actions/types';
import {ReactNode} from 'react';

export const toggleSpinner = (): GeneralAction => {
    return {
        type: TOGGLE_LOADING,
        payload: {},
    };
};

export const toggleMenu = (): GeneralAction => {
    return {
        type: TOGGLE_MENU,
        payload: {},
    };
};

export const toggleToast = (tipo: 'success' | 'error' | 'warning', messaggio: string | ReactNode): GeneralAction => {
    if (tipo === 'success') {
        toast.success(messaggio, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
        });
    } else if (tipo === 'error') {
        toast.error(messaggio, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
        });
    } else if (tipo === 'warning') {
        toast.warning(messaggio, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            className: 'color-primary',
        });
    }

    return {
        type: '',
        payload: {},
    };
};

export const setSelectedPage = (page: string) => {
    return {
        type: SELECTED_PAGE,
        payload: {
            message: page,
        },
    };
};

export const resetStore = (): GeneralAction => {
    return {
        type: RESET_STORE,
        payload: {},
    };
};

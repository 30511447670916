import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import { useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getRoutes } from '../../routing/utils';
import { RootState } from '../../store/reducers';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { authCheckState } from '../../store/actionCreators/auth';
import { getClasses } from '../../utils/general';

import Header from '../Header/Header';
import { getStaticContent } from '../../store/actionCreators/enum';
import { handleGetUtente, handleGetUtentiPerHolding } from '../../store/actionCreators/utenti';
import { IUtente } from '../../utils/model_types';
import { setAvviso } from '../../store/actionCreators/avviso';
import axiosInstance from '../../api/interceptor';
import HelpButton from '../HelpButton/HelpButton';

const App = () => {
    const storeIsLoading = useSelector((state: RootState) => {
        const { loading } = state.general;
        return loading;
    });
    const { user } = useSelector((state: RootState) => state.auth);
    const { utente } = useSelector((state: RootState) => state.utenti);
    const { ruoli } = useSelector((state: RootState) => state.enum);
    const [, setShow] = useState(false);
    const mainRef = useRef(null);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(authCheckState());
        window.onscroll = () => (window.scrollY > 250 ? setShow(true) : setShow(false));

        window.addEventListener('storage', () => {
            dispatch(authCheckState());
        });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // ENUMERATORS
        if (user) {
            dispatch(getStaticContent());
            dispatch(handleGetUtente(user?.userID || ''));
        }

        getAvviso();

        // eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        if (utente && user?.role === ruoli.REF_IMP) {
            if (utente.impresa === null) {
                //     history.push(`/profilo/${utente.id}`)
            } else {
                dispatch(handleGetUtentiPerHolding());
            }
        }
        // eslint-disable-next-line
    }, [utente, ruoli]);

    const getAvviso = () => {
        axiosInstance.get('notice').then(({ data }) => {
            if (data?.message) dispatch(setAvviso(data.message));
        });
    };

    return (
        <>
            {!user || user.firstAccess ? (
                getRoutes(user, utente as IUtente)
            ) : (
                <div id="app-container" ref={mainRef} className={getClasses([storeIsLoading && 'no-event'])}>
                    <div id="layout-container">
                        <Header />
                        <div id="wrapper">
                            <main id="main">{getRoutes(user, utente as IUtente)}</main>
                        </div>
                    </div>
                </div>
            )}
            <ToastContainer autoClose={5000} />
            {storeIsLoading && <LoadingSpinner />}
            {user && <HelpButton />}
        </>
    );
};

export default App;

import React from 'react';
import { IInputFormProps } from '../../utils/model_types';
import { VALIDATION_MESSAGES } from '../../utils/constants';
import { getMessageWithParam } from '../../utils/utils';
import './FormElement.css';
import IconInput from './IconInput/IconInput';

const FormElement = ({
    label,
    id,
    className,
    valid,
    validationError,
    validationParam,
    children,
    iconInfoType,
}: IInputFormProps) => {
    return (
        <div className={`form-row-element ${className || ''}`} id={id}>
            {label && (
                <label style={{ whiteSpace: 'nowrap' }}>
                    {label}
                    {iconInfoType && <IconInput type={iconInfoType} />}
                </label>
            )}
            <div className="input-wrapper">
                {children}
                {!valid && (
                    <div className="error-message">
                        {getMessageWithParam(
                            typeof validationError === 'string'
                                ? validationError
                                : VALIDATION_MESSAGES[validationError || ''],
                            validationParam || null
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default FormElement;

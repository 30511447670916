import { Role } from '../../routing/types';
import { AUTH_FAIL, AUTH_LOGOUT, AUTH_START, AUTH_SUCCESS } from '../actions/auth';
import { AuthAction } from '../actions/types';
import { User } from '../../utils/model_types';

// import {ADMIN} from "../actions/general";

export interface AuthState {
    access_token: string | undefined;
    user: User;
    loading: boolean;
    error: boolean;
    authority: Role;
    authCheckEnd: boolean;
    isLoading: boolean;
}

const initialState: AuthState = {
    access_token: undefined,
    user: undefined,
    loading: false,
    error: false,
    authority: '',
    authCheckEnd: false,
    isLoading: false,
};

const authReducer = (state = initialState, action: AuthAction): AuthState => {
    switch (action.type) {
        case AUTH_START: {
            return {
                ...state,
                error: false,
                loading: true,
                authCheckEnd: false,
                isLoading: true,
            };
        }
        case AUTH_SUCCESS: {
            const { user } = action.payload;
            return {
                ...state,
                loading: false,
                access_token: user?.access_token,
                user: user,
                authority: user?.role || '',
                error: false,
                authCheckEnd: true,
                isLoading: false,
            };
        }
        case AUTH_FAIL: {
            return {
                ...state,
                error: true,
                loading: false,
                authCheckEnd: true,
                isLoading: false,
            };
        }
        case AUTH_LOGOUT: {
            return {
                user: undefined,
                access_token: undefined,
                loading: false,
                error: false,
                authority: '',
                isLoading: false,
                authCheckEnd: true,
            };
        }
        default:
            return state;
    }
};

export default authReducer;

import axios, { AxiosResponse } from 'axios';

const { REACT_APP_BASE_URL } = process.env;
const TIMEOUT = 60 * 1000;

const axiosInstance = axios.create({
    timeout: TIMEOUT,
    baseURL: REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

const errorHandler = (error: any) => {
    if (error.response) {
        const {
            status,
            data: { message, errors },
        } = error.response;
        let messaggio;

        switch (status) {
            case 401: {
                switch (message) {
                    case 'Credenziali non valide':
                    case 'Bad credentials': {
                        messaggio =
                            'Username e/o Password errata, se necessario utilizzare "Hai dimenticato la password?"';
                        break;
                    }
                    case 'Utente disabilitato':
                    case 'User is disabled': {
                        messaggio =
                            "La sua utenza non risulta attiva, le chiediamo di inviare una richiesta attraverso l'apposito tasto indicando l'errore e la username utilizzata";
                        break;
                    }
                    default:
                        messaggio = 'Non sei autorizzato. Controlla le tue credenziali.';
                }
                localStorage.removeItem('access_token');
                localStorage.removeItem('expirationDate');
                localStorage.removeItem('user');
                localStorage.removeItem('path');
                break;
            }
            case 413: {
                messaggio = 'Dimensione dei file caricati troppo grande';
                break;
            }
            default:
                messaggio = message ? message : 'Errore generico!';
        }

        if (errors?.length > 0) {
            messaggio = errors[0].defaultMessage;
        }

        return Promise.reject({ messaggio, error });
    }
};

const successHandler = (response: AxiosResponse) => {
    return response;
};

axiosInstance.interceptors.response.use(
    (response) => successHandler(response),
    (error) => errorHandler(error)
);

export default axiosInstance;

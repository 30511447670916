import { EnumAction } from '../actions/types';
import {
    SET_RUOLI,
    SET_SEDI,
    SET_SESSI,
    SET_SINDACATI,
    SET_STATI_CONCILIAZIONE,
    SET_STATI_UTENTI,
    SET_TIPI_CONCILIAZIONE,
    SET_TIPI_GIORNATA,
} from '../actions/enum';
import { IEnumObj, ISede } from '../../utils/model_types';

export interface enumState {
    sedi: ISede[];
    ruoli: IEnumObj;
    sessi: IEnumObj;
    statiUtenti: IEnumObj;
    statiConciliazione: IEnumObj;
    tipiConciliazione: IEnumObj;
    tipiGiornata: IEnumObj;
    sindacati: IEnumObj;
}

const initialState: enumState = {
    sedi: [],
    // sediStraordinarie: {},
    ruoli: {},
    sessi: {},
    statiUtenti: {},
    statiConciliazione: {},
    tipiConciliazione: {},
    tipiGiornata: {},
    sindacati: {},
};

const enumReducer = (state = initialState, action: EnumAction): enumState => {
    switch (action.type) {
        case SET_SEDI: {
            const { sedi } = action.payload;
            return {
                ...state,
                sedi,
            };
        }
        // case SET_SEDI_STRAORDINARIE: {
        //     const {sediStraordinarie} = action.payload;
        //     return {
        //         ...state,
        //         sediStraordinarie,
        //     }
        // }
        case SET_RUOLI: {
            const { ruoli } = action.payload;
            return {
                ...state,
                ruoli,
            };
        }
        case SET_SESSI: {
            const { sessi } = action.payload;
            return {
                ...state,
                sessi,
            };
        }
        case SET_STATI_UTENTI: {
            const { statiUtenti } = action.payload;
            return {
                ...state,
                statiUtenti,
            };
        }
        case SET_STATI_CONCILIAZIONE: {
            const { statiConciliazione } = action.payload;
            return {
                ...state,
                statiConciliazione,
            };
        }
        case SET_TIPI_CONCILIAZIONE: {
            const { tipiConciliazione } = action.payload;
            return {
                ...state,
                tipiConciliazione,
            };
        }
        case SET_TIPI_GIORNATA: {
            const { tipiGiornata } = action.payload;
            return {
                ...state,
                tipiGiornata,
            };
        }
        case SET_SINDACATI: {
            const { sindacati } = action.payload;
            return {
                ...state,
                sindacati,
            };
        }
        default:
            return state;
    }
};

export default enumReducer;

export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const TOGGLE_TOAST = 'TOGGLE_TOAST';

export const SELECTED_PAGE = 'SELECTED_PAGE';

export const PAGE_REGEX = /page=(\d*?)&/;
export const PAGE_SIZE_REGEX = /&size=(\d*?)>/;

export const BASIC_USER = 'BASIC_USER';
export const ADMIN = 'ADMIN';

export const ROWS_PER_PAGE = [5, 10, 20, 50];

export const RESET_STORE = 'RESET_STORE';

import React from 'react';
import './IconInput.css';

interface IconInputInterface {
    type?: string;
}

const IconInput = ({ type }: IconInputInterface) => {
    return (
        <div className="tooltip">
            <i className={'fas fa-info'} />
            <span className="tooltiptext">
                {type === 'file' && (
                    <div>
                        Dimensione massima consentita : 5 MB <br />
                        Formati ammessi: *.png,*.jfif,*.pjpeg,*.jpeg,*.pjp,*.jpg,
                        <br />
                        *.tiff,*.tif,*.dot,*.doc,*.docx,*.pdf,*.heic
                    </div>
                )}
                {type === 'password' && (
                    <div>
                        La password deve contenere almeno una cifra [0-9].
                        <br />
                        La password deve contenere almeno un carattere minuscolo [a-z].
                        <br />
                        La password deve contenere almeno un carattere maiuscolo [A-Z].
                        <br />
                        La password deve contenere almeno un carattere speciale come !@#$%^&*
                        <br />
                        La password deve contenere almeno 8 caratteri e un massimo di 20 caratteri.
                        <br />
                    </div>
                )}
                {type === 'impresa' && (
                    <div>
                        Ricerca una impresa in autocompletamento. <br />
                        Selezionare dalla lista dei risultati l'impresa desiderata per selezionarla. <br />
                        Se la ricerca non ha prodotto risultati, cliccare sul "+" per aggiungere l'impresa desiderata.{' '}
                        <br />
                        Se non verrà selezionata alcuna impresa, il campo resterà vuoto. <br />
                    </div>
                )}
                {type === 'verbaleConciliazione' && (
                    <div>Compilando questo campo potrai utilizzare un verbale unico per ogni dipendente.</div>
                )}
            </span>
        </div>
    );
};
export default IconInput;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FormElement from '../../components/FormElement/FormElement';
import { handleGetUtente, handleGetUtentiPerRole, sendSegnalazione } from '../../store/actionCreators/utenti';
import { RootState } from '../../store/reducers';
import { IFormField, ISegnalazioneRequest, IUtente } from '../../utils/model_types';
import { getRoleDescription, submitForm } from '../../utils/utils';
import InternalPage from '../InternalPage/InternalPage';
import './Contattaci.css';
import SegnalazioneInviataModal from '../../components/Modals/SegnalazioneInviataModal/SegnalazioneInviataModal';

const Contattaci = () => {
    const initialFormFields = {
        tipoSegnalazione: {
            field: 'tipoSegnalazione',
            value: '',
            validations: {
                required: true,
            },
            validationError: null,
            valid: true,
        },
        elencoNominativi: {
            field: 'elencoNominativi',
            value: '',
            validations: {
                required: true,
            },
            validationError: null,
            valid: true,
        },
        utente: {
            field: 'utente',
            value: '',
            validations: {
                required: true,
            },
            validationError: null,
            valid: true,
        },
        messaggio: {
            field: 'messaggio',
            value: '',
            validations: {
                required: true,
            },
            validationError: null,
            valid: true,
        },
    };

    const [formFields, setFormFields] = useState<{ [key: string]: IFormField }>(initialFormFields);
    const [title] = useState('Contattaci');
    const [currentUtente, setCurrentUtente] = useState<IUtente | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const tipiSegnalazione = ['INFORMAZIONE', 'RICHIESTA', 'SEGNALAZIONE'];
    const nominativi = ['ANIA_CONC', 'ANIA_GEST'];
    const { utente, utenti } = useSelector((state: RootState) => state.utenti);
    const { userId } = useParams<{ userId: string }>();
    const { REACT_APP_CONTATTACI_SUBTITLE } = process.env;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(handleGetUtente(userId as string));
    }, [dispatch, userId]);

    useEffect(() => {
        if (formFields.elencoNominativi.value) {
            dispatch(handleGetUtentiPerRole(formFields.elencoNominativi.value));
        }
        // eslint-disable-next-line
    }, [formFields.elencoNominativi.value]);

    useEffect(() => {
        if (utente && !currentUtente && utente.id === userId) {
            setCurrentUtente({ ...utente });
        }
        // eslint-disable-next-line
    }, [utente]);

    const handleChangeFormField = (value: string, field: any) => {
        setFormFields({
            ...formFields,
            [field.field]: {
                ...field,
                value,
                valid: true,
            },
        });
    };

    const handleChangeUtente = (value: string) => {
        setFormFields({
            ...formFields,
            elencoNominativi: {
                ...formFields.elencoNominativi,
                value,
            },
            utente: {
                ...formFields.utente,
                value: '',
            },
        });
    };

    const sendMessaggio = (e: React.MouseEvent<HTMLButtonElement>) => {
        const formIsValid = submitForm(e, formFields, setFormFields);
        if (formIsValid) {
            const segnalazione: ISegnalazioneRequest = {
                tipoSegnalazione: formFields.tipoSegnalazione.value.toUpperCase(),
                idUtente: utenti.find((u) => u.id === formFields.utente.value)?.id as string,
                messaggio: formFields.messaggio.value,
            };
            dispatch(sendSegnalazione(segnalazione, setOpen));
        }
    };

    return (
        <InternalPage title={title} subtitle={REACT_APP_CONTATTACI_SUBTITLE || ''}>
            <form id="user-form">
                <div className="form-row grid-3">
                    <FormElement
                        label={'Tipo segnalazione'}
                        valid={formFields.tipoSegnalazione.valid}
                        validationError={formFields.tipoSegnalazione.validationError}
                    >
                        <select
                            name="tipoSegnalazione"
                            id="tipoSegnalazione"
                            value={formFields.tipoSegnalazione.value}
                            //disabled={readOnly || (userIsAniaConc || userIsRefSind || conciliazioneIsNonSvolta || conciliazioneIsConclusa)}
                            onChange={({ target: { value } }) =>
                                handleChangeFormField(value, formFields.tipoSegnalazione)
                            }
                        >
                            <option value="" unselectable="on" disabled={true}>
                                Seleziona un tipo
                            </option>
                            {Object.values(tipiSegnalazione).map((key) => (
                                <option key={key} value={key}>
                                    {key?.replaceAll('_', ' ')}
                                </option>
                            ))}
                        </select>
                    </FormElement>
                    <FormElement
                        label={'Elenco nominativi'}
                        valid={formFields.elencoNominativi.valid}
                        validationError={formFields.elencoNominativi.validationError}
                    >
                        <select
                            name="elencoNominativi"
                            id="elencoNominativi"
                            value={formFields.elencoNominativi.value}
                            onChange={({ target: { value } }) => handleChangeUtente(value)}
                        >
                            <option value="" unselectable="on" disabled={true}>
                                Seleziona un nominativo
                            </option>
                            {nominativi.map((el) => (
                                <option key={el} value={el}>
                                    {getRoleDescription(el)}
                                </option>
                            ))}
                        </select>
                    </FormElement>
                    {utenti && formFields.elencoNominativi.value && (
                        <FormElement
                            label={'Elenco utenti'}
                            valid={formFields.utente.valid}
                            validationError={formFields.utente.validationError}
                        >
                            <select
                                name="utente"
                                id="utente"
                                value={formFields.utente.value}
                                onChange={({ target: { value } }) => handleChangeFormField(value, formFields.utente)}
                            >
                                <option value="" unselectable="on" disabled={true}>
                                    Seleziona un utente
                                </option>
                                {utenti.map((el) => (
                                    <option key={el.id} value={el.id}>
                                        {el.nome} {el.cognome}
                                    </option>
                                ))}
                            </select>
                        </FormElement>
                    )}
                    <FormElement
                        label={'Messaggio'}
                        valid={formFields.messaggio.valid}
                        validationError={formFields.messaggio.validationError}
                        className="text-area-contatti"
                    >
                        <textarea
                            name="messaggio"
                            id="messaggio"
                            value={formFields.messaggio.value}
                            onChange={({ target: { value } }) => handleChangeFormField(value, formFields.messaggio)}
                        />
                    </FormElement>
                </div>
                <div className="footer">
                    <button type="button" onClick={sendMessaggio}>
                        Invia
                    </button>
                </div>
            </form>
            {open && <SegnalazioneInviataModal open={open} form={formFields} />}
        </InternalPage>
    );
};

export default Contattaci;

import { Dispatch } from 'react';
import {
    ConciliazioneAction,
    GeneralAction,
    GiornateAction,
    GiornatePageableAction,
    SedeAction,
} from '../actions/types';
import { toggleSpinner, toggleToast } from './general';
import axiosInstance from '../../api/interceptor';
import {
    IAssociazioneAniaConciliatore,
    IConciliazione,
    IGiornataRequest,
    IGiornataResponse,
    IGiornataSimple,
    IGiornatePageable,
    IPagination,
    ISede,
} from '../../utils/model_types';
import { SET_GIORNATE, SET_GIORNATE_LIST, SET_GIORNATE_PAGEABLE, SET_TIPOLOGIA_GIORNATA } from '../actions/giornate';
import moment from 'moment';
import { setSede } from './sede';
import { MOBILE_MAX_WIDTH, ORA_CONCILIAZIONE_FORMAT, TABLET_MAX_WIDTH } from '../../utils/constants';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

export const setGiornate = (giornate: IGiornataResponse[]): GiornateAction => {
    return {
        type: SET_GIORNATE,
        payload: { giornate },
    } as GiornateAction;
};

export const setGiornateList = (giornateList: IGiornataSimple[]): GiornateAction => {
    return {
        type: SET_GIORNATE_LIST,
        payload: { giornateList },
    } as GiornateAction;
};

export const setGiornatePageable = (giornatePageable: IGiornatePageable): GiornatePageableAction => {
    return {
        type: SET_GIORNATE_PAGEABLE,
        payload: { giornatePageable },
    };
};

export const setTipologiaGiornata = (tipologiaGiornata: string): GiornateAction => {
    return {
        type: SET_TIPOLOGIA_GIORNATA,
        payload: { tipologiaGiornata },
    } as GiornateAction;
};

export const getGiornate = ({
    sede,
    page,
    firstView,
    tipologiaGiornata,
}: {
    sede?: ISede | null;
    page?: number;
    firstView?: boolean;
    tipologiaGiornata: string;
}) => {
    return (dispatch: Dispatch<GiornatePageableAction | GeneralAction>) => {
        dispatch(toggleSpinner());
        let url = 'giornata/calendario?sort=dataGiornata,ASC';
        if (page) url = url.concat(`&page=${page}`);

        url = url.concat(
            `&size=${window.innerWidth < MOBILE_MAX_WIDTH ? 1 : window.innerWidth <= TABLET_MAX_WIDTH ? 3 : 5}`
        );
        if (sede) url = url.concat(`&sedeGiornata=${sede.id}`);
        if (tipologiaGiornata) url = url.concat(`&tipoGiornata=${tipologiaGiornata}`);
        url = url.concat(`&firstView=${firstView}`);

        axiosInstance
            .get(url)
            .then(({ data }) => {
                const giornate = data.content.map((giornata: IGiornataResponse) => {
                    giornata.conciliazioniGiornata = giornata.conciliazioniGiornata.map((conciliazione) => {
                        if (conciliazione.tipoConciliazione === 'COLLETTIVA') {
                            conciliazione.conciliazioniContenute = conciliazione.conciliazioniContenute.sort(
                                (a: IConciliazione, b: IConciliazione) => {
                                    return moment(a.oraInizio, ORA_CONCILIAZIONE_FORMAT).diff(
                                        moment(b.oraInizio, ORA_CONCILIAZIONE_FORMAT),
                                        'minutes'
                                    );
                                }
                            );
                        }
                        return conciliazione;
                    });
                    return giornata;
                });

                const giornatePageable: IGiornatePageable = {
                    giornate: giornate || [],
                    pagination: {
                        page: page || data.number,
                        totalPages: data.totalPages,
                        size: data.size,
                    },
                };
                dispatch(setGiornatePageable(giornatePageable));
                dispatch(toggleSpinner());
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', messaggio));
            });
    };
};

const getSede = ({
    giornata,
    firstView,
    page,
    tipologiaGiornata,
}: {
    giornata: IGiornataRequest;
    firstView: boolean;
    page?: number;
    tipologiaGiornata: string;
}) => {
    return (dispatch: Dispatch<GeneralAction | GiornateAction | SedeAction | any>) => {
        const sede = giornata.sedeGiornata;
        if (sede) dispatch(setSede(sede));
        else dispatch(setSede(null));
        dispatch(getGiornate({ sede, firstView, page, tipologiaGiornata }));
    };
};

export const addGiornata = ({
    giornata,
    handleOpenAddDates,
    firstView,
    page,
    tipologiaGiornata,
}: {
    giornata: IGiornataRequest;
    handleOpenAddDates: Function;
    firstView: boolean;
    page?: number;
    tipologiaGiornata: string;
}) => {
    return (dispatch: Dispatch<GeneralAction | GiornateAction | any>) => {
        dispatch(toggleSpinner());
        axiosInstance
            .post('giornata', giornata)
            .then(({ data }) => {
                dispatch(getSede({ giornata, firstView, page, tipologiaGiornata }));
                dispatch(toggleToast('success', 'Giornata caricata correttamente'));
                dispatch(toggleSpinner());
                handleOpenAddDates();
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', messaggio));
            });
    };
};

export const deleteGiornata = (
    giornata: IGiornataResponse,
    page: number,
    firstView: boolean,
    tipologiaGiornata: string
) => {
    return (dispatch: Dispatch<GiornateAction | GeneralAction | any>) => {
        dispatch(toggleSpinner());
        axiosInstance
            .delete(`giornata/${giornata.id}`)
            .then(() => {
                dispatch(getGiornate({ sede: giornata.sedeGiornata, page: page, firstView, tipologiaGiornata }));
                dispatch(toggleToast('success', 'Giornata cancellata correttamente'));
                dispatch(toggleSpinner());
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', messaggio));
            });
    };
};

export const closeGiornata = (
    giornata: IGiornataResponse,
    pagination: IPagination | undefined,
    firstView: boolean,
    handleToggleDayMenu: Function,
    tipologiaGiornata: string,
) => {
    return (dispatch: Dispatch<GiornateAction | GeneralAction | any>) => {
        dispatch(toggleSpinner());
        axiosInstance
            .put(`giornata/conferma/${giornata.id}`)
            .then(({ data }) => {
                dispatch(
                    getGiornate({
                        sede: giornata.sedeGiornata,
                        firstView,
                        page: pagination?.page,
                        tipologiaGiornata,
                    })
                );
                dispatch(toggleToast('success', 'Giornata chiusa correttamente'));
                dispatch(toggleSpinner());
                handleToggleDayMenu(null);
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', messaggio));
            });
    };
};

export const forceCloseGiornata = (
  giornata: IGiornataResponse,
  pagination: IPagination | undefined,
  firstView: boolean,
  handleToggleDayMenu: Function,
  tipologiaGiornata: string,
) => {
  return (dispatch: Dispatch<GiornateAction | GeneralAction | any>) => {
    dispatch(toggleSpinner());
    axiosInstance
      .put(`giornata/forceconferma/${giornata.id}`)
      .then(({ data }) => {
        dispatch(
          getGiornate({
            sede: giornata.sedeGiornata,
            firstView,
            page: pagination?.page,
            tipologiaGiornata,
          })
        );
        dispatch(toggleToast('success', 'Giornata chiusa correttamente'));
        dispatch(toggleSpinner());
        handleToggleDayMenu(null);
      })
      .catch((e) => {
        const { messaggio } = e;
        dispatch(toggleSpinner());
        dispatch(toggleToast('error', messaggio));
      });
  };
};

export const openGiornata = (
  giornata: IGiornataResponse,
  pagination: IPagination | undefined,
  firstView: boolean,
  handleToggleDayMenu: Function,
  tipologiaGiornata: string
) => {
  return (dispatch: Dispatch<GiornateAction | GeneralAction | any>) => {
    dispatch(toggleSpinner());
    axiosInstance
      .put(`giornata/apri/${giornata.id}`)
      .then(({ data }) => {
        dispatch(
          getGiornate({
            sede: giornata.sedeGiornata,
            firstView,
            page: pagination?.page,
            tipologiaGiornata,
          })
        );
        dispatch(toggleToast('success', 'Giornata riaperta correttamente'));
        dispatch(toggleSpinner());
        handleToggleDayMenu(null);
      })
      .catch((e) => {
        const { messaggio } = e;
        dispatch(toggleSpinner());
        dispatch(toggleToast('error', messaggio));
      });
  };
};

export const getGiornateList = ({ sede }: { sede: string }) => {
    return (dispatch: Dispatch<ConciliazioneAction | GeneralAction | any>) => {
        dispatch(toggleSpinner());
        let url = 'giornata/giorniInseriti';
        if (sede) url = url.concat('?sedeGiornata=' + sede);

        axiosInstance
            .get(url)
            .then(({ data }) => {
                dispatch(toggleSpinner());
                dispatch(setGiornateList(data));
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', messaggio));
            });
    };
};

export const downloadExcelGiornata = (dispatch: Dispatch<ConciliazioneAction | GeneralAction>, idGiornata: string) => {
    return async (dispatch: any) => {
        dispatch(toggleSpinner());
        let url = '/giornata/export-giornata-scheduled/'.concat(idGiornata);

        const headers = { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' };
        const config: AxiosRequestConfig = { method: 'GET', url, responseType: 'arraybuffer', headers };

        axiosInstance
            .get(url, config)
            .then((response: AxiosResponse) => {
                dispatch(toggleSpinner());
                const contentDisposition = response.request.getResponseHeader('Content-Disposition');
                const match = contentDisposition.match(/filename\s*=\s*"(.+)"/i);
                const filename = match[1];
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', messaggio));
            });
    };
};

export const associaConciliatoreToGiornata = (
    dispatch: Dispatch<GiornateAction | GeneralAction>,
    {
        associaConciliatore,
        toggleModal,
        handleToggleDayMenu,
        sede,
        firstView,
        page,
        tipologiaGiornata,
    }: {
        associaConciliatore: IAssociazioneAniaConciliatore;
        sede: ISede | null;
        firstView: boolean;
        page: number;
        toggleModal: Function;
        handleToggleDayMenu?: Function;
        tipologiaGiornata: string;
    }
) => {
    return (dispatch: any) => {
        dispatch(toggleSpinner());
        axiosInstance
            .put('giornata/associaConciliatore', associaConciliatore)
            .then(({ data }) => {
                dispatch(getGiornate({ sede, firstView, page, tipologiaGiornata }));
                dispatch(toggleToast('success', 'Conciliatore ANIA associato correttamente'));
                dispatch(toggleSpinner());
                toggleModal(null);
                if (handleToggleDayMenu) handleToggleDayMenu(null);
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', messaggio));
            });
    };
};

import { Fragment, lazy } from 'react';
import Contattaci from '../pages/Contattaci/Contattaci';
import { IRoute } from './types';

const Calendario = lazy(() => import('../pages/Calendario/Calendario'));
const ConfermaPassword = lazy(() => import('../pages/ConfermaPassword/ConfermaPassword'));
const Documentazione = lazy(() => import('../pages/Documentazione/Documentazione'));
const ElencoImprese = lazy(() => import('../pages/ElencoImprese/ElencoImprese'));
const ElencoHolding = lazy(() => import('../pages/ElencoHolding/ElencoHolding'));
const FAQs = lazy(() => import('../pages/FAQs/FAQs'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword/ForgotPassword'));
const GestioneUtenti = lazy(() => import('../pages/GestioneUtenti/GestioneUtenti'));
const Informazioni = lazy(() => import('../pages/Informazioni/Informazioni'));
const LandingPage = lazy(() => import('../pages/LandingPage/LandingPage'));
const Login = lazy(() => import('../pages/Login/Login'));
const PortalePronto = lazy(() => import('../pages/PortalePronto/PortalePronto'));
const Profilo = lazy(() => import('../pages/Profilo/Profilo'));
const Register = lazy(() => import('../pages/Register/Register'));
const Search = lazy(() => import('../pages/Search/Search'));
const Assistenza = lazy(() => import('../pages/Assistenza/Assistenza'));
const GestioneSedi = lazy(() => import('../pages/GestioneSedi/GestioneSedi'));

/*
 TIPOLOGIE DI ACCESSO
    0: visibile a tutti gli utenti (non autenticati, autenticati e autenticati con privilegi)
    1: visibile solo agli utenti non autenticati
    2: visibile a tutti utenti autenticati
    3: visibile solo agli utenti autenticati con un determinato ruolo
        In caso di accesso di tipo 3 bisogna indicare nell'array "roles" quali possono accedere alla pagina.
        Il tipo di dato da inserire all'interno di "roles" può essere di qualsiasi tipo (numero, stringa ecc.)

 Per impostare un path nei route senza farlo comparire nei link basta mettere il name come stringa vuota
 */

/* ========== Components attached to routes ==========
        ATTENZIONE! Usare sempre il lazy loading
*/

// Array contente tutte le pagine con relative configurazioni

const defaultRoute: IRoute = {
    path: '/',
    name: '',
    isExternalPage: false,
    logo: {},
    component: Fragment,
    access: [2],
    private: false,
    roles: [],
    style: {},
    showInHeader: true,
    showInLateralMenu: false,
    withCommonTemplate: false,
};

export const routes: IRoute[] = [
    {
        ...defaultRoute,
        path: '/calendario',
        name: 'Calendario',
        component: Calendario,
        access: [2],
        roles: [],
        showInHeader: true,
        showInLateralMenu: true,
    },
    {
        ...defaultRoute,
        path: '/firstAccess',
        name: 'Cambia Password',
        component: ConfermaPassword,
        access: [2],
        roles: [],
        showInHeader: false,
    },
    {
        ...defaultRoute,
        path: '/documentazione',
        name: 'Documentazione',
        component: Documentazione,
        access: [2],
        roles: [],
        showInHeader: true,
        showInLateralMenu: true,
    },
    {
        ...defaultRoute,
        path: '/elenco-imprese',
        name: 'Elenco Imprese',
        component: ElencoImprese,
        access: [3],
        roles: ['ANIA_GEST'],
        showInHeader: false,
        showInLateralMenu: true,
    },
    {
        ...defaultRoute,
        path: '/elenco-holding',
        name: 'Elenco Holding',
        component: ElencoHolding,
        access: [3],
        roles: ['ANIA_GEST'],
        showInHeader: false,
        showInLateralMenu: true,
    },
    {
        ...defaultRoute,
        path: '/faqs',
        name: 'FAQs',
        component: FAQs,
        access: [2],
        roles: [],
        showInHeader: true,
        showInLateralMenu: true,
    },
    {
        ...defaultRoute,
        path: '/forgot-password',
        name: 'Forgot Password',
        component: ForgotPassword,
        access: [1],
        roles: [],
        showInHeader: false,
    },
    {
        ...defaultRoute,
        path: '/gestione-utenti',
        name: 'Gestione utenti',
        component: GestioneUtenti,
        access: [3],
        roles: ['ANIA_GEST'],
        showInHeader: false,
        showInLateralMenu: true,
    },
    {
        ...defaultRoute,
        path: '/informazioni',
        name: 'Informazioni',
        component: Informazioni,
        access: [2],
        roles: [],
        showInHeader: true,
        showInLateralMenu: true,
    },
    {
        ...defaultRoute,
        path: '/contattaci',
        name: 'Contattaci',
        component: Contattaci,
        access: [3],
        roles: [],
        showInHeader: false,
        showInLateralMenu: false,
    },
    {
        ...defaultRoute,
        path: '/landing-page',
        name: 'Landing Page',
        component: LandingPage,
        access: [1],
        roles: [],
        showInHeader: false,
    },
    {
        ...defaultRoute,
        path: '/login',
        name: 'Login',
        component: Login,
        access: [1],
        roles: [],
        showInHeader: false,
    },
    {
        ...defaultRoute,
        path: '/portale-pronto',
        name: 'Portale pronto',
        component: PortalePronto,
        access: [1],
        roles: [],
        showInHeader: true,
        showInLateralMenu: true,
    },
    {
        ...defaultRoute,
        path: '/profilo/:userId',
        name: 'Profilo',
        component: Profilo,
        access: [2],
        roles: [],
        showInHeader: false,
        showInLateralMenu: true,
    },
    {
        ...defaultRoute,
        path: '/register',
        name: 'Register',
        component: Register,
        access: [1],
        roles: [],
        showInHeader: false,
    },
    {
        ...defaultRoute,
        path: '/ricerca',
        name: 'Ricerca',
        component: Search,
        access: [2],
        roles: [],
        showInHeader: false,
    },
    {
        ...defaultRoute,
        path: '/assistenza',
        name: 'Assistenza',
        component: Assistenza,
        access: [0],
        roles: [],
        showInHeader: false,
        showInLateralMenu: true,
    },
    {
        ...defaultRoute,
        path: '/gestione-sedi',
        name: 'Gestione Sedi',
        component: GestioneSedi,
        access: [3],
        roles: ['ANIA_GEST'],
        showInHeader: false,
        showInLateralMenu: true,
    },
];

/**
 * Esempio di array routes
 *
 * [
 {
        ...defaultRoute,
        path: "/login",
        component: Login,
        access: [1],
        roles: []
    },
 {
        ...defaultRoute,
        name: "Page not found",
        path: "/404",
        component: UnimplementedRoute,
        access: [0],
        roles: [],
        showInHeader: false
    },
 {
        ...defaultRoute,
        name: "Crea Utente",
        path: "/crea-utente",
        component: CreateUser,
        access: [2],
        roles: [ADMIN],
        showInHeader: false
    },
 ];
 */

import { SedeAction } from '../actions/types';
import { SET_SEDE, SET_SEDI_PAGEABLE } from '../actions/sede';
import { ISede, ISediPageable } from '../../utils/model_types';

export interface SedeState {
    sede: ISede | null;
    sediPageable: ISediPageable;
}

const initialState: SedeState = {
    sede: null,
    sediPageable: { sedi: [], pagination: { page: 0, size: 10}},
};

const sedeReducer = (state = initialState, action: SedeAction): SedeState => {
    switch (action.type) {
        case SET_SEDE: {
            const { sede } = action.payload;
            return {
                ...state,
                sede: sede,
            };
        }
        case SET_SEDI_PAGEABLE: {
            return {
                ...state,
                sediPageable: action.payload.sediPageable || [],
            };
        }
        default:
            return state;
    }
};

export default sedeReducer;

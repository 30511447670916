import { PAGE_REGEX, PAGE_SIZE_REGEX } from '../store/actions/general';
import { IPagination } from './model_types';

export const getClasses = (...classes: any[]) => classes.filter(Boolean).join(' ');

export const scrollTop = () => {
    setTimeout(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, 200);
};

export const convertEuro = (num: number) => {
    return num
        ? new Intl.NumberFormat('it-IT', {
              style: 'currency',
              currency: 'EUR',
          }).format(num)
        : new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(0);
};

export const updatePagination = (pagination: IPagination, paginationHeaderLink: string[], totalCount: string) => {
    const paginationUpdated = { ...pagination };

    paginationHeaderLink.forEach((link: string) => {
        /**
         * considero il link "next" perchè mi indica la pagina successiva lato server, che corrisponde alla pagina reale frontend.
         * Non è necessario aggiungere un +1 alla page
         */
        if (link.includes('next')) {
            const page = link.match(PAGE_REGEX);
            const size = link.match(PAGE_SIZE_REGEX);
            paginationUpdated.page = page ? Number(page[1]) : 0;
            paginationUpdated.size = size ? Number(size[1]) : 0;
        } else if (link.includes('last')) {
            const page = link.match(PAGE_REGEX);
            paginationUpdated.totalPages = page ? Number(page[1]) : 0;
        }
        paginationUpdated.totalElements = Number(totalCount) || 0;
    });
    return paginationUpdated;
};

import { SET_HOLDINGS } from '../actions/holding';
import { HoldingAction } from '../actions/types';
import { IHoldingResponse } from '../../utils/model_types';

export interface HoldingState {
    holdings: IHoldingResponse[];
}

const initialState: HoldingState = {
    holdings: [],
};

const holdingReducer = (state = initialState, action: HoldingAction): HoldingState => {
    switch (action.type) {
        case SET_HOLDINGS: {
            return {
                ...state,
                holdings: action.payload.holdings || [],
            };
        }
        default:
            return state;
    }
};

export default holdingReducer;

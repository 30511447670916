import { combineReducers } from 'redux';
import authReducer from './auth';
import generalReducer from './general';
import impreseReducer from './imprese';
import holdingReducer from './holding';
import utentiReducer from './utenti';
import sedeReducer from './sede';
import conciliazioneReducer from './conciliazione';
import giornateReducer from './giornate';
import faqsInfoReducer from './faqsInfo';
import enumReducer from './enum';
import documentazioneReducer from './documentazione';
import { RESET_STORE } from '../actions/general';
import avvisoReducer from './avviso';

const appReducer = combineReducers({
    auth: authReducer,
    general: generalReducer,
    imprese: impreseReducer,
    holding: holdingReducer,
    utenti: utentiReducer,
    giornate: giornateReducer,
    conciliazione: conciliazioneReducer,
    sede: sedeReducer,
    faqsInfo: faqsInfoReducer,
    enum: enumReducer,
    documentazione: documentazioneReducer,
    avviso: avvisoReducer,
});

// reset the state of a redux store
export const rootReducer = (state: any, action: any) => {
    if (action.type === RESET_STORE) {
        state = action.payload;
    }
    return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

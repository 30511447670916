import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import helpBtn from '../../assets/img/assistenza.svg';
import expandedHelpBtn from '../../assets/img/assistenza-expanded.svg';
import './HelpButton.scss';

function HelpButton() {
    const history = useHistory();

    const [helpIcon, setHelpIcon] = useState(helpBtn)

    return (
        <div 
            className="help-button" 
            onClick={() => history.push('/assistenza')}
            onMouseOver={() => setHelpIcon(expandedHelpBtn)}
            onMouseOut={() => setHelpIcon(helpBtn)}
        >
            <img src={helpIcon} alt="Richiedi assistenza" />
        </div>
    );
}

export default HelpButton;

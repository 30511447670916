import { Dispatch } from 'react';
import { ConciliazioneAction, GeneralAction, UtentiAction } from '../actions/types';
import { toggleSpinner, toggleToast } from './general';
import axiosInstance from '../../api/interceptor';
import { INotifica, ISegnalazioneRequest, IUtente, IUtentiPageable } from '../../utils/model_types';
import { SET_NOTIFICHE, SET_UTENTE, SET_UTENTI, SET_UTENTI_PAGEABLE, SET_UTENTI_TAB } from '../actions/utenti';
import {AxiosRequestConfig, AxiosResponse} from 'axios';

const setUtentiPageable = (utentiPageable: IUtentiPageable): UtentiAction => {
    return {
        type: SET_UTENTI_PAGEABLE,
        payload: {
            utentiPageable,
        },
    } as UtentiAction;
};

const setUtenti = (utenti: IUtente[]): UtentiAction => {
    return {
        type: SET_UTENTI,
        payload: {
            utenti,
        },
    } as UtentiAction;
};

const setUtente = (utente: IUtente): UtentiAction => {
    return {
        type: SET_UTENTE,
        payload: {
            utente,
        },
    } as UtentiAction;
};

const setNotifiche = (notifiche: INotifica[]): UtentiAction => {
    return {
        type: SET_NOTIFICHE,
        payload: {
            notifiche,
        },
    } as UtentiAction;
};

const setUtentiTab = (tab: string): UtentiAction => {
    return {
        type: SET_UTENTI_TAB,
        payload: {
            tab,
        },
    } as UtentiAction;
};

export const handlePostUtente = (user: any, history: any) => {
    return (dispatch: Dispatch<GeneralAction | UtentiAction>) => {
        dispatch(toggleSpinner());

        axiosInstance
            .post('utente/registra', user)
            .then((res) => {
                dispatch(toggleToast('success', 'Richiesta di registrazione inviata correttamente'));
                dispatch(toggleToast('success', 'Le credenziali di accesso saranno fornite da Ania'));
                dispatch(toggleSpinner());
                history.push('/landing-page');
            })
            .catch((error) => {
                dispatch(toggleToast('error', error.messaggio));
                dispatch(toggleSpinner());
            });
    };
};

export const handlePutUtente = (user: any, history: any) => {
    return (dispatch: Dispatch<GeneralAction | UtentiAction>) => {
        dispatch(toggleSpinner());

        let url = 'utente';
        if (user) url = url.concat('/' + user.id);
        axiosInstance
            .put(url, user)
            .then((res) => {
                dispatch(toggleToast('success', 'Utente modificato correttamente'));
                dispatch(toggleSpinner());
                history.push('/gestione-utenti');
            })
            .catch((error) => {
                dispatch(toggleToast('error', error.messaggio));
                dispatch(toggleSpinner());
            });
    };
};

export const handleGetUtente = (userId: string) => {
    return (dispatch: Dispatch<GeneralAction | UtentiAction>) => {
        dispatch(toggleSpinner());

        let url = 'utente';
        if (userId) url = url.concat('/' + userId);

        axiosInstance
            .get(url)
            .then(({ data }) => {
                dispatch(setUtente(data));
                dispatch(toggleSpinner());
            })
            .catch((e) => {
                dispatch(toggleToast('error', e.messaggio));
                dispatch(toggleSpinner());
            });
    };
};

export const handleGetUtentiPageable = ({ page, size }: { page?: number; size?: number }) => {
    return (dispatch: Dispatch<GeneralAction | UtentiAction>) => {
        dispatch(toggleSpinner());

        let url = 'utente';
        if (page !== undefined && page >= 0 && size) url = url.concat('?page=' + page + '&size=' + size);

        axiosInstance
            .get(url)
            .then(({ data }) => {
                const utentiPageable: IUtentiPageable = {
                    content: data.content,
                    pagination: {
                        totalElements: data.totalElements,
                        size: data.size,
                        totalPages: data.totalPages,
                        page: data.number,
                    },
                };
                dispatch(setUtentiPageable(utentiPageable));
                dispatch(toggleSpinner());
            })
            .catch((e) => {
                dispatch(toggleToast('error', e.messaggio));
                dispatch(toggleSpinner());
            });
    };
};

export const handleGetUtentiPerRolePromise = (role: string) => {
    let url = 'utente';
    if (role) url = url.concat('/ruolo?ruoloUtente=' + role);
    return axiosInstance.get(url);
};

export const handleGetUtentiPerHolding = () => {
    return (dispatch: Dispatch<GeneralAction | UtentiAction>) => {
        dispatch(toggleSpinner());
        let url = 'utente/sameHolding';
        axiosInstance
            .get(url)
            .then(({ data }: { data: IUtente[] }) => {
                dispatch(setUtenti(data.filter((u) => u.statoUtente === 'ATTIVO')));
                dispatch(toggleSpinner());
            })
            .catch((e) => {
                dispatch(toggleToast('error', e.messaggio));
                dispatch(toggleSpinner());
            });
    };
};

export const handleGetUtentiPerRole = (role: string) => {
    return (dispatch: Dispatch<GeneralAction | UtentiAction>) => {
        dispatch(toggleSpinner());

        handleGetUtentiPerRolePromise(role)
            .then(({ data }: { data: IUtente[] }) => {
                dispatch(setUtenti(data));
                dispatch(toggleSpinner());
            })
            .catch((e) => {
                dispatch(toggleToast('error', e.messaggio));
                dispatch(toggleSpinner());
            });
    };
};

export const handleToggleStatoUtente = ({
    id,
    currentStato,
    role,
    setConfirmDisattivaUtente,
}: {
    id: string;
    currentStato: boolean;
    role?: string;
    setConfirmDisattivaUtente?: Function;
}) => {
    return (dispatch: Dispatch<GeneralAction | UtentiAction | any>) => {
        dispatch(toggleSpinner());
        let url = 'utente';
        if (id) url = url.concat('/' + id + '/stato/' + !currentStato);
        axiosInstance
            .put(url)
            .then(({ data }) => {
                dispatch(toggleSpinner());
                dispatch(toggleToast('success', currentStato ? 'Utente disattivato' : 'Utente attivato'));
                if (role) dispatch(handleGetUtentiPerRole(role));
                if (setConfirmDisattivaUtente) setConfirmDisattivaUtente(null);
            })
            .catch((e) => {
                dispatch(toggleToast('error', e.messaggio));
                dispatch(toggleSpinner());
            });
    };
};

export const handleDeleteUtente = ({ id, role }: { id: string; role?: string }) => {
    return (dispatch: Dispatch<GeneralAction | UtentiAction | any>) => {
        dispatch(toggleSpinner());
        let url = 'utente';
        if (id) url = url.concat('/' + id);
        axiosInstance
            .delete(url)
            .then(({ data }) => {
                dispatch(toggleSpinner());
                dispatch(toggleToast('success', 'Utente eliminato'));
                if (role) dispatch(handleGetUtentiPerRole(role));
            })
            .catch((e) => {
                dispatch(toggleToast('error', e.messaggio));
                dispatch(toggleSpinner());
            });
    };
};

export const handleResetPassword = ({ id }: { id: string }) => {
    return (dispatch: Dispatch<GeneralAction | UtentiAction | any>) => {
        dispatch(toggleSpinner());
        let url = 'utente/reset';
        if (id) url = url.concat('/' + id);
        axiosInstance
          .get(url)
          .then(({ data }) => {
              dispatch(toggleSpinner());
              dispatch(toggleToast('success', 'Password resettata'));
          })
          .catch((e) => {
              dispatch(toggleToast('error', e.messaggio));
              dispatch(toggleSpinner());
          });
    };
};

export const downloadFileUtente = ({ id, path, filename }: { id: string; path: string; filename: string }) => {
    return (dispatch: Dispatch<GeneralAction | UtentiAction>) => {
        dispatch(toggleSpinner());
        let url = 'utente';
        if (url) url = url.concat('/' + path);
        if (id) url = url.concat('/' + id);
        axiosInstance
            .get(url)
            .then(({ data }) => {
                const a = document.createElement('a');
                a.href = data.fileData;
                a.download = data.nameFile || filename || path;
                a.click();
                dispatch(toggleSpinner());
            })
            .catch((e) => {
                dispatch(toggleToast('error', e.messaggio));
                dispatch(toggleSpinner());
            });
    };
};

export const downloadDelegaConciliatore = (id: string) => {
    return (dispatch: Dispatch<GeneralAction | UtentiAction>) => {
        dispatch(toggleSpinner());
        let url = 'delega-conciliatore';
        if (id) url = url.concat('/' + id);
        axiosInstance
            .get(url)
            .then(({ data }) => {
                const a = document.createElement('a');
                a.href = data.dataFile;
                a.download = data.nomeFile;
                a.click();
                dispatch(toggleSpinner());
            })
            .catch((e) => {
                dispatch(toggleToast('error', e.messaggio));
                dispatch(toggleSpinner());
            });
    };
};

export const handleDeleteDelegaConciliatore = ({ id, userId }: { id: string; userId: string }) => {
    return (dispatch: Dispatch<GeneralAction | UtentiAction | any>) => {
        dispatch(toggleSpinner());
        let url = 'delega-conciliatore';
        if (id) url = url.concat('/' + id);
        axiosInstance
            .delete(url)
            .then(({ data }) => {
                dispatch(toggleSpinner());
                dispatch(toggleToast('success', 'Delega eliminata'));
                dispatch(handleGetUtente(userId));
            })
            .catch((e) => {
                dispatch(toggleToast('error', e.messaggio));
                dispatch(toggleSpinner());
            });
    };
};

export const handleGetNotifiche = () => {
    return (dispatch: Dispatch<GeneralAction | UtentiAction>) => {
        dispatch(toggleSpinner());

        let url = 'tipo-notifica/all';

        axiosInstance
            .get(url)
            .then(({ data }) => {
                dispatch(setNotifiche(data));
                dispatch(toggleSpinner());
            })
            .catch((e) => {
                dispatch(toggleToast('error', e.messaggio));
                dispatch(toggleSpinner());
            });
    };
};

export const downloadManualeUtente = (role: string) => {
    return (dispatch: Dispatch<GeneralAction | UtentiAction>) => {
        dispatch(toggleSpinner());
        let url = 'manuale';
        if (role) url = url.concat('/ruolo?ruoloUtente=').concat(role);
        axiosInstance
            .get(url)
            .then(({ data }) => {
                if (data.length > 0) {
                    const a = document.createElement('a');
                    a.href = data[0]?.fileData;
                    a.download = data[0]?.nameFile || 'manuale utente';
                    a.click();
                }
                dispatch(toggleSpinner());
            })
            .catch((e) => {
                dispatch(toggleToast('error', e.messaggio));
                dispatch(toggleSpinner());
            });
    };
};

export const downloadExcelUtentiPerRole = (role: string) => {
    return async (dispatch: Dispatch<ConciliazioneAction | GeneralAction>) => {
        dispatch(toggleSpinner());
        let url = '/utente/';
        if (role === 'REF_SIND') {
            url = url.concat('export-referenti-sindacali');
        } else if (role === 'REF_IMP') {
            url = url.concat('export-referenti-impresa');
        }

        axiosInstance
            .get(url, {
                headers: {
                    'Content-Type': 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet',
                },
                responseType: 'blob',
            })
            .then((response: AxiosResponse) => {
                dispatch(toggleSpinner());
                const blob = new Blob([response.data], {
                    type: `application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet`,
                });
                const url = window.URL.createObjectURL(blob); // creiamo l'url al Blob
                const a = document.createElement('a'); // creiamo un elemento nel DOM
                const contentDisposition = response.request.getResponseHeader('Content-Disposition');
                const match = contentDisposition.match(/filename\s*=\s*"?(.+)"?/i);
                const filename = match[1];
                a.href = url; // appendiamo l'url del Blob all'elemento creato
                a.download = filename; // appendiamo la proprietà di download con nome ed estension del file
                a.click(); // aggiungiamo il click event per procedere al download
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(toggleSpinner())
                dispatch(toggleToast('error', messaggio));
            });
    };
};

export const sendSegnalazione = (segnalazione: ISegnalazioneRequest, setOpen: Function) => {
    return (dispatch: Dispatch<GeneralAction>) => {
        dispatch(toggleSpinner());
        axiosInstance
            .post('utente/contattaci', segnalazione)
            .then((res) => {
                dispatch(toggleToast('success', 'Segnalazione inviata correttamente'));
                dispatch(toggleSpinner());
                setOpen(true);
            })
            .catch((error) => {
                dispatch(toggleToast('error', error.messaggio));
                dispatch(toggleSpinner());
            });
    };
};

export const handleSetActiveTab = (tab: string) => {
  return  (dispatch: Dispatch<UtentiAction>) => dispatch(setUtentiTab(tab));
};

import CustomModal from '../CustomModal/CustomModal';
import FormElement from '../../FormElement/FormElement';
import React, { useState } from 'react';
import './SelectDatesModal.scss';
import { submitForm } from '../../../utils/utils';
import { IFormField } from '../../../utils/model_types';
import moment from 'moment';
import { BE_DATE_FORMAT, DATE_FORMAT } from '../../../utils/constants';

interface IProps {
    isOpen: boolean;
    handleOpen: Function;
    handleDownload: Function;
}

const SelectDatesModal = ({ isOpen, handleOpen, handleDownload }: IProps) => {
    const initialFormFields = {
        da: {
            field: 'da',
            value: moment().format(BE_DATE_FORMAT),
            validations: {
                required: true,
            },
            validationError: null,
            valid: true,
        },
        a: {
            field: 'a',
            value: moment().add(1, 'days').format(BE_DATE_FORMAT),
            validations: {
                required: true,
                dateEqualOrGreaterThan: moment().format(BE_DATE_FORMAT),
            },
            valid: true,
        },
    };
    const [formFields, setFormFields] = useState<{ [key: string]: IFormField }>(initialFormFields);

    const handleChangeFormField = (value: string, field: IFormField) => {
        setFormFields({
            ...formFields,
            [field.field]: {
                ...field,
                value,
                valid: true,
            },
        });
    };

    const handleChangeDa = (value: string) => {
        setFormFields({
            ...formFields,
            da: {
                ...formFields.da,
                value,
                valid: true,
            },
            a: {
                ...formFields.a,
                validations: {
                    ...formFields.a.validations,
                    dateEqualOrGreaterThan: moment(value).format(BE_DATE_FORMAT),
                },
                valid: true,
            },
        });
    };

    const handleCancel = (e: any) => {
        e.preventDefault();
        handleOpen();
    };

    const submit = (e: any) => {
        const formIsValid = submitForm(e, formFields, setFormFields);

        if (formIsValid)
            handleDownload({
                da: formFields.da.value,
                a: formFields.a.value,
            });
    };

    const footer = (
        <>
            <button type="button" className="bg-secondary" onClick={handleCancel}>
                Annulla
            </button>
            <button type="submit" onClick={submit}>
                Download
            </button>
        </>
    );

    return (
        <CustomModal
            id="select-dates-modal"
            title="Download excel"
            size="md"
            open={isOpen}
            toggleModal={() => handleOpen()}
            footer={footer}
        >
            <div className="modal-content-body">
                <form className="form-select-dates">
                    <div className="form-row grid-2">
                        <FormElement
                            label={'Da'}
                            valid={formFields.da.valid}
                            validationError={formFields.da.validationError}
                        >
                            <input
                                id="data"
                                type="date"
                                value={formFields.da.value}
                                onChange={({ target: { value } }) => handleChangeDa(value)}
                            />
                        </FormElement>
                        <FormElement
                            label={'A'}
                            valid={formFields.a.valid}
                            validationError={formFields.a.validationError}
                            validationParam={moment(formFields.da.value, BE_DATE_FORMAT).format(DATE_FORMAT)}
                        >
                            <input
                                id="data"
                                type="date"
                                value={formFields.a.value}
                                onChange={({ target: { value } }) => handleChangeFormField(value, formFields.a)}
                            />
                        </FormElement>
                    </div>
                </form>
            </div>
        </CustomModal>
    );
};

export default SelectDatesModal;

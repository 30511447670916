import { AvvisoAction } from '../actions/types';
import { SET_AVVISO } from '../actions/avviso';

export interface AvvisoState {
    avviso: string | null;
}

const initialState: AvvisoState = {
    avviso: null,
};

const avvisoReducer = (state = initialState, action: AvvisoAction): AvvisoState => {
    switch (action.type) {
        case SET_AVVISO: {
            const { avviso } = action.payload;
            return {
                ...state,
                avviso,
            };
        }
        default:
            return state;
    }
};

export default avvisoReducer;

import { Suspense } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { routes } from './routes';
import { IRoute, Role } from './types';
import { IUtente, User } from '../utils/model_types';

export const parseJwt = (token: string) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

/**
 * Da usare se si necessita di una struttura comune nelle pagine
 * @param ComponentRouteObject
 * @constructor
 */
function CustomComponent({ component, name }: IRoute) {
    const history = useHistory();
    return function (props: any) {
        const Component = component;
        return (
            <div className="common-template-container">
                {history.length > 1 && (
                    <span className="back-icon cursor-pointer" onClick={() => history.goBack()}>
                        &lt; Indietro
                    </span>
                )}
                <h1>{name}</h1>
                <Component {...props} />
            </div>
        );
    };
}

export const getRoles = (user: User) => {
    return user?.role || '';
};

const getVisibleToAllRoutes = () => routes.filter((r) => r.access.includes(0));
const getPublicRoutes = () => routes.filter((r) => r.access.includes(1));
const getPrivateRoutes = () => routes.filter((r) => r.access.includes(2));
const getPrivateRoutesWithRoles = (authority: Role) =>
    routes.filter((r) => r.access.includes(3) && authority && r.roles.some((r) => authority === r));

const getRouteComponent = (routes: IRoute[]) =>
    routes.map((r, i) =>
        r.withCommonTemplate ? (
            <Route path={r.path} exact key={i}>
                {CustomComponent(r)}
            </Route>
        ) : (
            <Route path={r.path} exact key={i} component={r.component} />
        )
    );

export const getRouteItems = (user: User, utente: IUtente) =>
    user
        ? getVisibleToAllRoutes()
              .concat(getPrivateRoutes(), getPrivateRoutesWithRoles(getRoles(user)))
              .filter((rotta) => {
                  if (user?.userID === utente?.id && utente?.ruoloUtente === 'REF_IMP' && utente.impresa === null) {
                      return rotta.path === '/profilo/:userId';
                  }
                  return rotta;
              })
        : getVisibleToAllRoutes().concat(getPublicRoutes());

export const getRoutes = (user: User, utente: IUtente) => {
    const loggedUser = user;

    return (
        <Suspense fallback={<div> Loading...</div>}>
            <Switch>
                {getRouteComponent(getRouteItems(loggedUser, utente))}
                <Redirect
                    to={
                        loggedUser
                            ? loggedUser.firstAccess
                                ? 'firstAccess'
                                : user?.userID === utente?.id &&
                                  utente?.ruoloUtente === 'REF_IMP' &&
                                  utente.impresa === null
                                ? '/profilo/' + user.userID
                                : '/calendario'
                            : '/login'
                    }
                />
            </Switch>
        </Suspense>
    );
};

import { resetStore, toggleSpinner, toggleToast } from './general';
import { AUTH_FAIL, AUTH_LOGOUT, AUTH_START, AUTH_SUCCESS } from '../actions/auth';
import { Action, AuthAction, GeneralAction, UtentiAction } from '../actions/types';
import { Dispatch } from 'react';
import axiosInstance from '../../api/interceptor';
import { parseJwt } from '../../routing/utils';
import { ILoggedUser } from '../../utils/model_types';

export const authStart = (): AuthAction => {
    return {
        type: AUTH_START,
        payload: {
            isLoading: true,
        },
    };
};

export const authSuccess = (user: ILoggedUser): AuthAction => {
    return {
        type: AUTH_SUCCESS,
        payload: {
            user: user,
        },
    };
};

export const authFail = (error: boolean): AuthAction => {
    return {
        type: AUTH_FAIL,
        payload: {
            error: error,
        },
    };
};

export const logout = (dispatch: any): AuthAction => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('user');
    localStorage.removeItem('path');
    dispatch(resetStore());
    return {
        type: AUTH_LOGOUT,
        payload: {
            role: '',
            authCheckEnd: false,
            error: false,
            isLoading: false,
            access_token: undefined,
            user: undefined,
        },
    };
};

export const checkAuthTimeout = (dispatch: Dispatch<AuthAction>, expirationTime: number) => {
    setTimeout(() => {
        dispatch(logout(dispatch));
    }, expirationTime * 1000);
};

// TODO: da tenere commentata finché non ci saranno le chiamate BE
export const auth = (
    dispatch: Dispatch<GeneralAction | AuthAction>,
    { username, password }: { username: string; password: string }
) => {
    return (dispatch: Dispatch<GeneralAction | AuthAction>) => {
        dispatch(authStart());
        dispatch(toggleSpinner());

        const bodyFormData = {
            username,
            password,
        };
        axiosInstance
            .post('auth/login', bodyFormData)
            .then(({ data }) => {
                const { accessToken } = data;
                const { exp, ruoloUtente, sub, firstAccess } = parseJwt(accessToken);
                const expirationDate = new Date(exp * 1000);
                const user = {
                    username,
                    role: ruoloUtente,
                    userID: sub,
                    access_token: accessToken,
                    firstAccess,
                };

                localStorage.setItem('access_token', accessToken);
                localStorage.setItem('expirationDate', expirationDate.toString());
                localStorage.setItem('user', JSON.stringify(user));

                dispatch(toggleSpinner());
                dispatch(authSuccess(user));
            })
            .catch((e) => {
                const { messaggio } = e;
                dispatch(authFail(messaggio || 'Se hai già attivato il tuo account controlla le credenziali'));
                dispatch(toggleSpinner());
                /**
                 * toggleToast: mostra un toast con un determinato messaggio.
                 * Per veder come funziona guardate la funzione toggleToast prensente in questo file src/store/actionCreators/General.js
                 * @param tipo: @string definisce il tipo di toast da lanciare: 'e' = errore => toast rosso, 's' = successo => toast verde
                 * @param messaggio: @string definisce il messaggio mostrato nel toast
                 */
                dispatch(
                    toggleToast('error', messaggio || 'Se hai già attivato il tuo account controlla le credenziali')
                );
            });
    };
};

export const authCheckState = () => (dispatch: Dispatch<Action>) => {
    authStart();

    const token = localStorage.getItem('access_token');
    if (!token) {
        dispatch(logout(dispatch));
        return false;
    } else {
        const expirationString = localStorage.getItem('expirationDate');
        if (!expirationString) return false;

        const expirationDate = new Date(expirationString);
        if (expirationDate.getTime() <= new Date().getTime()) {
            dispatch(logout(dispatch));
            return false;
        } else {
            const user = localStorage.getItem('user');
            if (!user) return false;
            dispatch(authSuccess(JSON.parse(user)));
            checkAuthTimeout(dispatch, (expirationDate.getTime() - new Date().getTime()) / 1000);
            return true;
        }
    }
};

export const handleConfermaPassword = (
    dispatch: Dispatch<GeneralAction | UtentiAction>,
    { id, password }: { id: string; password: string }
) => {
    return (dispatch: any) => {
        dispatch(toggleSpinner());

        let url = 'auth/confermaPassword/';
        if (id) url = url.concat(id);
        const user = JSON.parse(localStorage.getItem('user') || '');

        const requestBody = {
            username: user.username,
            password,
        };

        axiosInstance
            .put(url, requestBody)
            .then(({ data }) => {
                dispatch(auth(dispatch, requestBody));
                dispatch(toggleSpinner());
            })
            .catch((e) => {
                dispatch(toggleToast('error', e?.messaggio));
                dispatch(toggleSpinner());
            });
    };
};

export const resetPassword = (dispatch: Dispatch<GeneralAction | UtentiAction>, username: string, history: any) => {
    return (dispatch: Dispatch<GeneralAction | AuthAction>) => {
        dispatch(toggleSpinner());

        let url = 'auth/resetPassword';
        if (username) url = url.concat('?username=' + username);
        axiosInstance
            .put(url)
            .then(({ data }) => {
                dispatch(toggleToast('success', 'richiesta inviata correttamente'));
                history.push('/login');
                dispatch(toggleSpinner());
            })
            .catch((e) => {
                dispatch(toggleToast('error', e?.messaggio));
                dispatch(toggleSpinner());
            });
    };
};

import { Dispatch } from 'react';
import { toggleSpinner, toggleToast } from './general';
import { GeneralAction, SedeAction } from '../actions/types';
import axiosInstance from '../../api/interceptor';
import { SET_SEDE, SET_SEDI_PAGEABLE } from '../actions/sede';
import { ISede, ISediPageable } from '../../utils/model_types';

export const setSede = (sede: ISede | null): SedeAction => {
    return {
        type: SET_SEDE,
        payload: { sede },
    } as SedeAction;
};

const setSediPageable = (sediPageable: ISediPageable): SedeAction => {
    return {
        type: SET_SEDI_PAGEABLE,
        payload: {
            sediPageable,
        },
    } as SedeAction;
};

export const handleGetSediPageable = (params: { page: number; size: number } = { page: 0, size: 10 }) => {
    return (dispatch: Dispatch<GeneralAction | SedeAction>) => {
        dispatch(toggleSpinner());

        axiosInstance
            .get(`sedi?${new URLSearchParams({
                size: params.size.toString(),
                page: params.page.toString(),
            }).toString()}`)
            .then(({ data }) => {
                const sediPageable: ISediPageable = {
                    sedi: data.content,
                    pagination: {
                        totalElements: data.totalElements,
                        size: data.size,
                        totalPages: data.totalPages,
                        page: data.number,
                    },
                };
                dispatch(setSediPageable(sediPageable));
                dispatch(toggleSpinner());
            })
            .catch((e) => {
                dispatch(toggleToast('error', e.messaggio));
                dispatch(toggleSpinner());
            });
    };
};


export const handleDeleteSede = (sedeId: string | null) => {
    return (dispatch: Dispatch<GeneralAction | SedeAction | any>) => {
        dispatch(toggleSpinner());

        let url = 'sede';
        if (sedeId) url = url.concat('/' + sedeId);

        axiosInstance
            .delete(url)
            .then(({ data }) => {
                dispatch(toggleToast('success', 'Sede cancellata'));
                dispatch(handleGetSediPageable());
                dispatch(toggleSpinner());
            })
            .catch((e) => {
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', e.messaggio));
            });
    };
};

export const handleUpdateSede = (sede: ISede) => {
    return (dispatch: Dispatch<GeneralAction | SedeAction | any>) => {
        dispatch(toggleSpinner());

        let url = 'sede';
        if (sede) url = url.concat('/' + sede.id);

        axiosInstance
            .put(url, sede)
            .then(({ data }) => {
                dispatch(toggleToast('success', 'Sede modificata'));
                dispatch(handleGetSediPageable());
                dispatch(toggleSpinner());
            })
            .catch((e) => {
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', e.messaggio));
            });
    };
};

export const handleAddSede = (sede: ISede) => {
    return (dispatch: Dispatch<GeneralAction | SedeAction | any>) => {
        dispatch(toggleSpinner());

        let url = 'sede';

        axiosInstance
            .post(url, sede)
            .then(({ data }) => {
                dispatch(toggleToast('success', 'Sede aggiunta'));
                dispatch(handleGetSediPageable());
                dispatch(toggleSpinner());
            })
            .catch((e) => {
                dispatch(toggleSpinner());
                dispatch(toggleToast('error', e.messaggio));
            });
    };
};

export const SET_CONCILIAZIONE = 'SET_CONCILIAZIONE';
export const SET_OPEN_CONTEXT_MENU = 'SET_OPEN_CONTEXT_MENU';
export const SET_SEARCHED_CONCILIAZIONI_PAGEABLE = 'SET_SEARCHED_CONCILIAZIONI_PAGEABLE';
export const SET_CONCILIAZIONI = 'SET_CONCILIAZIONI';
export const SET_CONCILIAZIONI_PAGEABLE = 'SET_CONCILIAZIONI_PAGEABLE';
export const SET_MOTIVI_CONCILIAZIONE = 'SET_MOTIVI_CONCILIAZIONE';
export const SET_CCNL = 'SET_CCNL';
export const SET_LIVELLO_INQUADRAMENTO = 'SET_LIVELLO_INQUADRAMENTO';
export const SET_DELEGHE = 'SET_DELEGHE';
export const SET_REFERENTI_IMPRESA = 'SET_REFERENTI_IMPRESA';
